.imageUploaderCustom {
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
}

.imageUploaderCustom__imageContainer {
  margin-right: 0.5rem;
  position: relative;
}

.imageUploaderCustom__image {
  object-fit: cover;
  /* width: 35px;
  height: 35px; */
  cursor: pointer;
  border-radius: 0.25rem;
  transition: transform 0.3s ease-in-out;
}

.imageUploaderCustom__image:hover {
  transform: scale(1.1);
}

.imageUploaderCustom__popup {
  position: absolute;
  background-color: #fff;
  border: 1px solid #7e7e95;
  border-radius: 5px;
  padding: 10px;
  z-index: 99;
}

.imageUploaderCustom__popupText {
  font-weight: bold;
}

.imageUploaderCustom__button--yes {
  margin-right: 10px;
  color: white;
  background-color: red;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
}

.imageUploaderCustom__button--no {
  color: white;
  background-color: green;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
}

.imageUploaderCustom__fileUploadContainer {
  cursor: pointer;
  width: 100%;
  /* width: 35px; */
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #00AA13;
  background-color: white;
  border-radius: 0.25rem;
  color: #00AA13;
  transition: all 0.3s ease-in-out;
  padding: 0 10px;
}

.imageUploaderCustom__fileUploadContainer:hover {
  border-color: #00AA13;
  background-color: white;
  color: #00AA13;
}

.imageUploaderCustom__fileUpload {
  display: none;
}

.fullScreenImage {
  position: fixed;
  top: 5%;
  left: 5%;
  right: 5%;
  bottom: 5%;
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
  margin: auto;
}

.imageUploaderCustom__removeIcon {
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 14px;
  transform: rotate(45deg);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.imageUploaderCustom__removeIcon:hover {
  animation: jiggle 0.3s infinite;
}

@keyframes jiggle {
  0% {
    transform: rotate(45deg) scale(1);
  }

  50% {
    transform: rotate(45deg) scale(1.1);
  }

  100% {
    transform: rotate(45deg) scale(1);
  }
}
