.ccm-input-wrapper {
  position: relative;

  .icon-box {
    position: absolute;
    left: 0;
    top: 45%;
    padding-left: 1rem;
    transform: translateY(-50%);
  }

  input {
    z-index: 1;
  }

  input+.icon-box {
    left: auto;
    right: 0;
    padding-left: unset;
    border-left: 1px solid $stroke;
    width: 40px;
  }

  .input-icon {
    &.copy-link {
      cursor: pointer;
    }

    svg {
      height: 20px;
      width: 20px;
    }
  }
}

.reject-textarea {
  border: 1px solid #E5E6E6;
  width: 100%;
  padding: 0.5rem 0.5rem;
}

::placeholder {
  color: #E5E6E6 !important;
}
