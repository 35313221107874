$customSizes: 6, 7, 8, 10, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 27, 29, 30, 32, 35, 38, 40, 42, 48, 50, 80, 97;
$customBoldSizes: 500, 600, 800;

@each $size in $customSizes {
  .fs-#{$size} {
    font-size: #{$size}px !important;
    line-height: #{$size}px;
  }

  .lh-#{$size} {
    line-height: #{$size}px !important;
  }

  .svg-#{$size} {

    &svg,
    svg {
      height: #{$size}px;
      width: #{$size}px;
    }
  }
}

@each $size in $customBoldSizes {
  .fw-#{$size} {
    font-weight: #{$size} !important;
  }
}

.fw-bold {
  font-weight: bold;
}
