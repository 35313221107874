.ccm-configuration {
  .sub-header {
    .content-left {
      h1 {
        font-weight: bold;
      }
    }

    .content-right {
      span {
        color: $gray;
        font-size: 13px;
        font-style: italic;

        em {
          &.failed {
            color: $primary;
          }

          &.saved {
            color: $green;
          }
        }
      }
    }
  }

  .content-left {
    .status {
      margin-left: 20px;
      font-size: 14px;
      color: $gray;
      border: 1px solid $stroke;
      border-radius: 4px;
      padding: 6px 8px;
      margin-bottom: 0;
      font-weight: 500;
    }
  }

  &__header {
    .search-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border: 1px solid #e1e2ea;
      padding: 8px 15px;
      align-items: center;

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        margin: 0;
      }
    }
  }

  &__wrapper {
    width: 100%;
    // height: 56px;
    // background: $bright-gray;

    .wrapper-search {
      width: 100%;
      height: 56px;
      background: $bright-gray;

      display: flex;
      align-items: center;
      padding: 0 15px;

      .input-group {
        width: 256px;

        span {
          padding-right: 5px;
        }

        input {
          border-left: none;
        }
      }
    }

    .wrapper-action {
      padding: 0 10px;
      width: 100%;
      height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h1 {
        font-size: 16px;
        color: $black-dark-30;
      }

      // .react-datepicker-wrapper {
      //   &.calendar {
      //   }
      // }

      .dropdown {
        margin-right: 15px;

        &.calendar {
          margin-right: 28px;
        }
      }

      button {
        // height: 32px;
      }

      .input-group {
        input {
          border-left: none;
        }
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    border: 1px solid #e1e2ea;
    border-radius: 4px;
    align-items: center;
    height: 48px;
    padding-left: 15px;

    p {
      margin-left: 10px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 0;
    }
  }

  &.detail {
    .dropdown-timesheet:disabled {
      background-color: #e9ecef !important;
      opacity: 1;
      color: #3E3E49 !important;
    }

    .sub-header {
      .content-right {
        span {
          color: $gray;
          font-size: 13px;
          font-style: italic;
        }

        button {
          width: 96px;
          margin-left: 10px;
        }
      }
    }

    .content-left {
      display: flex;
      align-items: center;

      button {
        background: transparent;
        display: flex;
        align-items: center;
        border: none;
        outline: none;

        &:focus {
          box-shadow: none;
        }
      }

      h1 {
        margin: 0;
        font-size: 21px;
        padding-left: 15px;
      }
    }

    .wrapper-action {
      padding: 20px 10px;
      border-bottom: 1px solid $stroke;
      display: flex;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;

        svg {
          margin-right: 10px;
        }

        h3 {
          font-size: 16px;
          color: $black-dark-20;
          margin: 0;
        }
      }

      .right {
        display: flex;

        .dropdown {
          margin-right: 15px;
        }
      }
    }

    .wrapper-input {
      padding: 20px 10px;

      label {
        font-size: 11px;
        color: $gray-dark-10;
      }

      .form-control:disabled {
        background-color: $bright-gray;
      }
    }

    .wrapper-content {
      min-height: 100vh;

      .title {
        padding: 10px;
        font-size: 14px;
        color: $primary;
        font-weight: 500;
        border-bottom: 1px solid $stroke;
      }

      .form-label {
        color: $gray;
        font-size: 11px;
      }

      .wrapper-section {
        background: $white;
        margin-bottom: 8px;
        border-top: 1px solid $stroke;
        border-bottom: 1px solid $stroke;

        .title {}

        .row {
          align-items: center;
        }

        .wrapper-header {
          .col-md-6 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid $stroke;
            min-height: 60px;

            &:first-child {
              border-left: none;
            }

            &:last-child {
              border-right: none;
            }
          }

          .col-md-5 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid $stroke;
            min-height: 60px;
            border-left: none;
          }

          .col-md-7 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid $stroke;
            min-height: 60px;
            border-right: none;
          }

          .left {
            display: flex;
            align-items: center;

            h3 {
              font-size: 14px;
              margin: 0;
              color: $black-dark-20;
              font-weight: 500;
              padding-left: 10px;
            }
          }

          .right {
            display: flex;
            align-items: center;

            h3 {
              margin: 0;
            }

            .ch-header {
              display: flex;
              align-items: center;

              h3 {
                font-size: 20px;
              }

              span {
                display: block;
                font-size: 14px;
                padding-right: 20px;
                padding-left: 20px;
              }
            }
          }
        }

        .total-removal {
          border: 1px solid $stroke;
          border-radius: 4px;
          height: 111px;
          margin: 0 10px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          font-weight: 500;

          .title {
            font-size: 18px;
            padding: 0;
          }

          .total-count {
            font-size: 24px;
          }
        }

        .list-removal {
          background: $bright-gray;
          padding: 10px;
          border-radius: 4px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .item-name {
            font-size: 14px;
            color: $gray;
          }

          .item-count {
            color: $black-dark-20;
            font-size: 14px;
            font-weight: bold;
            margin: 0;
          }
        }
      }

      .wrapper-table {
        min-height: 140px;
        padding-bottom: 10px;

        .col-md-6 {
          padding: 0;
        }

        .empety-data {
          text-align: center;
          color: $gray;
          font-size: 14px;
          display: block;
          padding: 30px 0;
        }

        .wrapper-button {
          height: 50px;
          text-align: center;
        }

        .btn {
          margin-top: 10px;

          &.btn-primary {
            width: 51px;
          }

          &.btn-secondary {
            width: 84px;
          }
        }

        .table {
          margin-bottom: 0;
        }

        .input-row {
          background: $gray-bg;
          padding: 10px 0;

          .col-md-4 {
            padding: 0 10px;
          }

          .ccm-input-wrapper {
            input[type='text'] {
              border-left: none;
            }

            input[type='time'] {
              // display: none;
            }
          }
        }
      }

      .table {
        &.primary {
          td {
            vertical-align: middle;
          }
        }
      }

      .ccm-input-wrapper {
        .no-border {
          border-left: none;
        }
      }
    }

    .action-footer {
      padding: 10px;
      display: flex;

      .upload-document {
        span {
          padding-right: 30px;
          font-size: 14px;
          color: $black-dark-20;
        }
      }

      .wrapper-document {
        display: flex;
        flex-wrap: wrap;

        .document {
          padding: 6px 18px;
          border-radius: 50px;
          border: 1px solid $stroke;
          margin-left: 10px;
          font-size: 14px;
          color: $gray;
          max-width: 170px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          cursor: pointer;

          svg {
            margin-left: 10px;
          }
        }
      }
    }

    .wrapper-table {
      .dropdown-toggle {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .table-component-wrapper {
      .table.primary {
        th {
          padding-right: 0;
        }
      }
    }

    .wrapper-summary {
      .sum-tc {
        border-right: 0px !important;
        border-bottom: 0px !important;
        width: 20%;
      }

      .title {
        padding: 10px;
        font-size: 14px;
        color: $primary;
        font-weight: 500;
      }

      .bbn {
        border-bottom: none !important;
      }

      .total-production {
        border-top: 1px solid $stroke;
        border-right: 1px solid $stroke;
        border-bottom: 1px solid $stroke;
        border-left: 1px solid $stroke;
        border-radius: 0px;
        height: 111px;
        margin: 0px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        font-weight: 500;
        padding: 10px;

        .title {
          font-size: 18px;
          padding: 0;
          text-align: center;
        }

        .total-count {
          font-size: 24px;
        }
      }

      .sum-row {
        display: flex;
        align-items: center;
        border: 1px solid $stroke;
        border-right: none;
        border-left: none;

        .sum-sub-row {
          width: 80%;

          .sr-sum {
            display: flex;

            .content-sr {
              width: 25%;
            }
          }
        }
      }
    }
  }

  &.port {
    .table-component-wrapper {
      overflow-x: scroll;
    }
  }

  .head-tab {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

// override modal approval styles
.modal-dialog {
  &.custom-approval {
    width: 100%;

    .modal-content {
      .modal-body {
        border-radius: 8px;
        padding: 0;

        .wrap-button {
          margin: 0;
          padding: 24px;
          background-color: #fff;
          border-radius: 8px;
        }
      }
    }

    .wrapper-input {
      .header {
        padding: 24px;
        border: 1px solid #e5e6e6;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
      }

      .body {
        background-color: #fff;

        .container {
          padding: 24px;
        }

        .custom-table {
          padding: 0 24px;

          .table-component-wrapper {
            padding: 0;
            border-radius: 8px;
            border: 1px solid #e5e6e6;
            overflow: hidden;

            .table-responsive {
              min-height: auto;
              height: auto;

              .table {
                margin: 0;

                &.primary {
                  th {
                    border-top: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.table-ewh {
  thead {
    tr {
      th {
        text-align: center !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 16px !important;
        color: #3B3B41 !important;
        padding: 0.5rem !important;
      }
    }
  }
}
