.ccm-heading {
  color: $black;
  font-size: 24px;
  font-weight: 700;
}

.ccm-heading-big {
  color: $black-dark-10;
  font-size: 32px;
  line-height: 40px;
  font-weight: bold;
}

.ccm-sub-heading {
  color: var(--black-dark-10);
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}

.ccm-sub-heading-light {
  color: $gray-dark;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.ccm-error {
  color: $red;
  font-size: 14px;
  margin-top: 4px;
}

.sdp-error {
  color: $red;
  font-size: 14px;
  margin-top: 4px;
}

.ccm-link {
  text-decoration: none;
  color: $black-dark-10;

  &:hover {
    cursor: pointer;
    color: $black-dark-10;
    border-bottom: 1px solid $black-dark-10;
    text-decoration: underline;
  }
}

.ccm-link-blue {
  text-decoration: none;
  color: $blue;

  &:hover {
    color: $blue-link;
    text-decoration: underline;
  }
}

.ccm-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--black-dark-10);
}

a {
  color: $blue-link;
  text-decoration: underline;

  &:hover {
    color: $blue;
  }
}

.ccm-text-green {
  color: var(--green);
}

.ccm-text-green-dark {
  color: var(--green-dark);
}

.ccm-text-green-pistal {
  color: var(--green-pistal);
}

.ccm-text-green-gold {
  color: var(--green-gold);
}

.ccm-text-green-light {
  color: var(--green-light);
}

.ccm-text-blue {
  color: var(--blue);
}

.ccm-text-white {
  color: var(--white);
}

.ccm-text-blue {
  color: var(--blue);
}

.ccm-text-blue-light {
  color: var(--blue-light);
}

.ccm-text-blue-dark {
  color: var(--blue-dark);
}

.ccm-text-blue-extra-dark {
  color: var(--blue-extra-dark);
}

.ccm-text-grey-dark {
  color: var(--gray-dark);
}

.ccm-text-disable {
  color: var(--gray);
}

.ccm-text-teal {
  color: var(--teal);
}

.ccm-text-orange {
  color: var(--orange);
}

.ccm-text-orange-light {
  color: var(--light-orange);
}

.ccm-text-orange-dark {
  color: var(--orange-dark);
}

.ccm-text-purple-light {
  color: var(--purple-light);
}

.ccm-text-purple {
  color: var(--purple);
}

.ccm-text-red {
  color: var(--red);
}

.ccm-text-red-dark {
  color: var(--red-dark);
}

.ccm-text-red-extra-dark {
  color: var(--red-extra-dark);
}

.ccm-text-black-dark {
  color: var(--black-dark-10);
}

.ccm-text-black-light {
  color: var(--black-light);
}

.ccm-text-black-lighter {
  color: var(--black-light-10);
}

.text-hightlighted {
  color: $blue;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}
