.ccm-survey {

	.content-left {

		.status {
			margin-left: 20px;
			font-size: 14px;
			color: $gray;
			border: 1px solid $stroke;
			border-radius: 4px;
			padding: 6px 8px;
			margin-bottom: 0;
			font-weight: 500;
		}
	}

	&__wrapper {

		.wrapper-search {
			width: 100%;
			height: 56px;
			background: $bright-gray;

			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding: 0 15px;

			.input-group {
				width: 256px;

				span {
					padding-right: 5px;
				}

				input {
					border-left: none;
					z-index: 1;
				}
			}
		}

		.wrapper-action {
			padding: 0 10px;
			width: 100%;
			height: 56px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.dropdown {
				margin-right: 15px;
			}

			button {
				// height: 32px;
			}

			.custom-select {
				&.min {
					min-width: 140px;
					&.100 {
						min-width: 100px;
					}
				}
				&.max {
					max-width: 140px;
				}
			}
		}
	}

	// =========================
	// WASTE SHORT TERM PLANNING
	// =========================
	&.detail {

		.sub-header {

			.content-right {

				span {
					color: $gray;
					font-size: 13px;
					font-style: italic;
					em {
						&.failed {color: $primary}
						&.saved {color: $green}
					}
				}

				button {
					width: 96px;
					margin-left: 10px;
				}
			}
		}

		.content-left {
			display: flex;
			align-items: center;

			button {
				background: transparent;
				display: flex;
				align-items: center;
				border: none;
				outline: none;

				&:focus {
					box-shadow: none;
				}
			}

			h1 {
				margin: 0;
				font-size: 21px;
				padding-left: 15px;
			}
		}

		.wrapper-action {
			padding: 10px;
			border-bottom: 1px solid $stroke;
			display: flex;
			justify-content: space-between;

			.left {
				display: flex;
				align-items: center;

				svg {
					margin-right: 10px;
				}

				h3 {
					font-size: 16px;
					color: $black-dark-20;
					margin: 0;
				}
			}

			.right {
				display: flex;

				.dropdown {
					margin-right: 15px;
				}
			}
		}

		.wrapper-content {
			background: $white;
			min-height: 100vh;

			.title {
				padding: 10px;
				font-size: 14px;
				color: $primary;
				font-weight: 500;
			}

			.form-label {
				color: $gray;
				font-size: 11px;
			}

			.wrapper-section {

				background: $white;
				border-top: 1px solid $stroke;
				border-bottom: 1px solid $stroke;

				.title {}

				.row {
					align-items: center;
				}

				.wrapper-header {

					.col-md-6 {

						display: flex;
						justify-content: space-between;
						align-items: center;
						border: 1px solid $stroke;
						min-height: 60px;

						&:first-child {
							border-left: none;
						}

						&:last-child {
							border-right: none;
						}
					}

					.col-md-5 {
						display: flex;
						justify-content: space-between;
						align-items: center;
						border: 1px solid $stroke;
						min-height: 60px;
						border-left: none;


					}

					.col-md-7 {
						display: flex;
						justify-content: space-between;
						align-items: center;
						border: 1px solid $stroke;
						min-height: 60px;
						border-right: none;
					}

					.left {
						display: flex;
						align-items: center;

						h3 {
							font-size: 14px;
							margin: 0;
							color: $black-dark-20;
							font-weight: 500;
							padding-left: 10px;
						}
					}

					.right {
						display: flex;
						align-items: center;

						h3 {
							margin: 0;
						}

						.ch-header {
							display: flex;
							align-items: center;

							h3 {
								font-size: 20px;
							}

							span {
								display: block;
								font-size: 14px;
								padding-right: 20px;
								padding-left: 20px;
							}
						}
					}
				}

				.total-removal {
					border: 1px solid $stroke;
					border-radius: 4px;
					height: 111px;
					margin: 0 10px;
					display: flex;
					align-items: center;
					flex-direction: column;
					justify-content: center;
					font-weight: 500;

					.title {
						font-size: 18px;
						padding: 0;
					}

					.total-count {
						font-size: 24px;
					}
				}

				.list-removal {
					background: $bright-gray;
					padding: 10px;
					border-radius: 4px;
					display: flex;
					justify-content: space-between;
					align-items: center;

					.item-name {
						font-size: 14px;
						color: $gray;
					}

					.item-count {
						color: $black-dark-20;
						font-size: 14px;
						font-weight: bold;
						margin: 0;
					}
				}

				.wrap-select {
					display: flex;
					flex-wrap: wrap;
					width: 100%;
					padding: 3px;

					.item-select {
						width: 33.33%;
						flex: 0 0 33.33%;
						padding: 0 8px;
						.custom-select {
							&.disabled {
								>div {
									background: #e9ecef !important;
									// match input component
									opacity: 1;
									border: 1px solid #ced4da !important;
									>div{
										>div {
											color: #212529;
										}
									}
								}
							}
						}
					}
				}
			}

			.accordion {
				.accordion-item {
					border: 0;

					.accordion-header {
						.accordion-button {
							font-weight: bold;
							border-bottom: 1px solid #E5E6E6;
							padding: 25px 10px;

							&:focus {
								border-color: none;
								outline: 0;
								box-shadow: none;
							}

							&:not(.collapsed) {
								background: $white;
								color: $black-dark;
							}
						}
					}

					.accordion-collapse {
						.accordion-body {
							padding: 0;
						}
					}
				}
			}

			.wrap-title {
				background-color: white;
				padding: 25px 10px;
				border-top: 1px solid #E5E6E6;

				>h6 {
					font-weight: 700;
					margin-bottom: 0;
				}
			}

			.wrapper-table {
				min-height: 140px;
				padding-bottom: 10px;

				.col-md-6 {
					padding: 0;
				}

				.empety-data {
					text-align: center;
					color: $gray;
					font-size: 14px;
					display: block;
					padding: 30px 0;
				}

				.wrapper-button {
					height: 50px;
					text-align: center;
				}

				.btn {
					margin-top: 10px;

					&.btn-primary {
						width: 51px;
					}

					&.btn-secondary {
						width: 84px;
					}
				}

				.table {
					margin-bottom: 0;

					>thead {
						>tr {
							background-color: #F5F6F6;

							>th {
								min-height: 40px;
								color: #3B3B41;
							}
						}
					}

					>tbody {
						>tr {
							>td {
								min-height: 40px;
							}
						}
					}
				}

				.input-row {
					background: $gray-bg;
					padding: 10px 0;

					.col-md-4 {
						padding: 0 10px;
					}

					.ccm-input-wrapper {

						input[type='text'] {
							border-left: none;
						}

						input[type="time"i] {
							// display: none;
						}
					}
				}
			}

			.wrap-btn {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 25px 10px 40px;

				>div {
					.btn-icon-custum {
						border-radius: 30px;
						margin-left: 16px;

						.icon-close {
							margin-left: 5px;
							width: 10px;
							height: 10px;
							fill: #ED5451;
							align-items: center;
							margin-top: -3px;
						}
					}
				}
			}

			.wrap-header {
				width: 100%;
				height: 24px;
				background: $bright-gray;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				padding-right: 20%;
			}

			.wrap-note {
				padding: 10px 10px 40px;

				>span {
					font-weight: 500;
					font-size: 11px;
					line-height: 13px;
					color: #6F6F77;
					margin-bottom: 13px;
				}

				.input-note {
					min-height: 120px;
					border-radius: 5px;
				}
			}

			.table {

				&.primary {
					td {
						vertical-align: middle;
					}
				}
			}

			.ccm-input-wrapper {

				.no-border {
					border-left: none;
				}

			}

		}

		.action-footer {
			padding: 10px;
			display: flex;

			.upload-document {
				span {
					padding-right: 30px;
					font-size: 14px;
					color: $black-dark-20;
				}
			}

			.wrapper-document {
				display: flex;
				flex-wrap: wrap;

				.document {
					padding: 6px 18px;
					border-radius: 50px;
					border: 1px solid $stroke;
					margin-left: 10px;
					font-size: 14px;
					color: $gray;
					max-width: 170px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					cursor: pointer;

					svg {
						margin-left: 10px;
					}
				}
			}
		}
	}

	&.grid-list {
		display: flex;
    flex-direction: column;
    // overflow: scroll;
    font-size: 14px;
    white-space: nowrap;
    width: 100%;
    min-width: min-content;
		>.row {
			// margin-bottom: 36px;
			&.border-top {
				border-top: solid var(--border-color) 1px;
			}
			.row {
				border-bottom: solid var(--border-color) 1px;
				&.no-border {
					border-bottom: none;
				}
				&.space {
					height: 36px;
					&.gray {
						background-color: #F5F6FA;
					}
				}
				.col {
					display: flex;
					align-items: center;
					padding: 12px 16px;
				}
			}
		}
	}
}

.reason {
	.massage {
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		margin-bottom: 13px;
	}

	.input-reason {
		min-height: 120px;
		border-radius: 5px;
	}
}
