    .tab-action {
      cursor: pointer;
      padding: 0;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #3B3B41;

      .left {
        display: flex;
        align-items: center;

        .active {
          background-color: #3B3B41;

          h3 {
            color: white;
          }
        }

        h3 {
          font-size: 16px;
          color: $black-dark-20;
          margin: 0;
        }
      }
    }
