.notifications {
  .container {
    padding: 12px;
    font-size: 10pt;
    text-align: left;
    border-bottom: 1px solid $stroke;
    cursor: pointer;
    &:hover {
      background-color: $gray-bg-10;
    }

    .approved {color: #00AA13}
    .rejected {color: $red}
    .request {color: $orange-dark}
    .note {
      color: #858A8F;
      font-style: italic;
      margin-bottom: 0;
    }
    &.last {
      text-align: center;
      color: #386DF5;
    }
    &:last-child {
      border-bottom: none;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    // &:not(:first-child) {
    // }
  }
}
