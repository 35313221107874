.ccm-short-planning-daily {
  .content-left {
    .status {
      margin-left: 20px;
      font-size: 14px;
      color: $gray;
      border: 1px solid $stroke;
      border-radius: 4px;
      padding: 6px 8px;
      margin-bottom: 0;
      font-weight: 500;
    }
  }

  &__wrapper {
    width: 100%;
    // height: 56px;
    // background: $bright-gray;

    .wrapper-search {
      width: 100%;
      height: 56px;
      background: $bright-gray;

      display: flex;
      align-items: center;
      padding: 0 15px;

      .input-group {
        width: 256px;

        span {
          padding-right: 5px;
        }

        input {
          border-left: none;
        }
      }
    }

    .wrapper-action {
      padding: 0 10px;
      width: 100%;
      height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h1 {
        font-size: 16px;
        color: $black-dark-30;
      }

      .dropdown {
        margin-right: 15px;
      }

      button {
        // height: 32px;
      }
    }
  }

  .fcy:disabled {
    background-color: inherit;
    opacity: 1;
  }

  .planning-table {
    height: 50vh;

    tr {
      td {
        input {
          border: none;
          padding: 0;
          -webkit-appearance: none;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type='number'] {
          -moz-appearance: textfield;
        }
      }
    }

    thead {
      tr {
        &:first-child {


          background: #e9e9e9;

          th {
            &:first-child {
              background: #e9e9e9;
              position: sticky;
              left: 0px;
            }

            &:nth-child(2) {
              background: #e9e9e9;
              position: sticky;
              left: 200px;
            }
          }

        }

        &:nth-child(2) {
          th {
            &:first-child {
              position: sticky;
              left: 0;
            }

            &:nth-child(2) {
              position: sticky;
              left: 200px;
            }
          }
        }

        &:nth-child(3) {
          th {
            &:first-child {
              position: sticky;
              left: 0;
            }

            &:nth-child(2) {
              position: sticky;
              left: 200px;
            }
          }
        }

        &:last-child {
          th {
            background: #F5F6F6;
          }
        }

        th {
          display: flex;
          justify-content: space-between;
          background: #e9e9e9;
          font-weight: 450;
          font-size: 14px;
          line-height: 16px;
          color: #3B3B41;
          padding: 1rem 0.5rem;
          border-bottom: none;

          &:first-child {
            position: sticky;
            left: 0;
          }

        }
      }
    }

    tbody {
      tr {
        background: white;

        &:nth-child(3),
        &:nth-child(5),
        &:nth-child(6) {
          background: #ECEDF1 !important;
          margin-top: 24px;
          padding: 0.5rem 0rem;
          border: none;

          td {
            background: #ECEDF1 !important;
            border: none;
          }
        }

        td {
          &:first-child {
            position: sticky;
            left: 0;
            background: inherit;
          }

          &:nth-child(2) {
            position: sticky;
            left: 200px;
            background: inherit;
          }

                    // &:nth-child(15) {
                    //   position: sticky;
                    //   right: -1px;
                    //   background: inherit;
                    //   border-left: 1px solid #E5E6E6;
                    //   display: flex;
                    //   justify-content: right;
                    // }
        }

      }
    }
  }

  .daily-table {
    height: 40vh;

    .big {
      thead {
        tr {
          &:first-child {
            th {
              &:nth-child(34) {
                position: sticky;
                right: -1px;
                border-left: 1px solid #E5E6E6;
                display: flex;
                justify-content: center;
              }
            }
          }
        }
      }

      tbody {
        tr {
          td {
            &:nth-child(34) {
              position: sticky;
              right: -1px;
              background: inherit;
              border-left: 1px solid #E5E6E6;
              display: flex;
              justify-content: right;
            }
          }
        }
      }
    }

    .med {
      thead {
        tr {
          &:first-child {
            th {
              &:nth-child(33) {
                position: sticky;
                right: -1px;
                border-left: 1px solid #E5E6E6;
                display: flex;
                justify-content: center;
              }
            }
          }
        }
      }

      tbody {
        tr {
          td {
            &:nth-child(33) {
              position: sticky;
              right: -1px;
              background: inherit;
              border-left: 1px solid #E5E6E6;
              display: flex;
              justify-content: right;
            }
          }
        }
      }
    }

    .short {
      thead {
        tr {
          &:first-child {
            th {
              &:nth-child(32) {
                position: sticky;
                right: -1px;
                border-left: 1px solid #E5E6E6;
                display: flex;
                justify-content: center;
              }
            }
          }
        }
      }

      tbody {
        tr {
          td {
            &:nth-child(32) {
              position: sticky;
              right: -1px;
              background: inherit;
              border-left: 1px solid #E5E6E6;
              display: flex;
              justify-content: right;
            }
          }
        }
      }
    }

    .vshort {
      thead {
        tr {
          &:first-child {
            th {
              &:nth-child(31) {
                position: sticky;
                right: -1px;
                border-left: 1px solid #E5E6E6;
                display: flex;
                justify-content: center;
              }
            }
          }
        }
      }

      tbody {
        tr {
          td {
            &:nth-child(31) {
              position: sticky;
              right: -1px;
              background: inherit;
              border-left: 1px solid #E5E6E6;
              display: flex;
              justify-content: right;
            }
          }
        }
      }
    }

    tr {
      td {
        input {
          border: none;
          padding: 0;
          -webkit-appearance: none;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type='number'] {
          -moz-appearance: textfield;
        }
      }
    }

    thead {
      tr {
        &:first-child {
          th {
            &:first-child {
              position: sticky;
              left: 0;
            }

            &:nth-child(2) {
              position: sticky;
              left: 240px;
            }
          }
        }

        &:last-child {
          th {
            background: #F5F6F6;
          }
        }

        th {
          &:nth-child(2) {
            justify-content: space-between;
          }

          display: flex;
          justify-content: center;

          font-weight: 450;
          font-size: 14px;
          line-height: 16px;
          color: #3B3B41;
          padding: 1rem 0.5rem;
          border-bottom: none;

          &:first-child {
            position: sticky;
            left: 0;
          }

        }
      }
    }

    tbody {
      tr {
        background: white;

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          background: #ECEDF1;
          margin-top: 24px;
          padding: 0.5rem 0rem;
          border: none;

          td {
            border: none;
          }
        }

        td {
          &:first-child {
            position: sticky;
            left: 0;
            background: inherit;
          }

          &:nth-child(2) {
            position: sticky;
            left: 240px;
            background: inherit;
          }
        }

      }
    }
  }

  .long-planning-table {
    height: 75vh;

    tr {
      td {
        input {
          border: none;
          padding: 0;
          -webkit-appearance: none;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type='number'] {
          -moz-appearance: textfield;
        }
      }
    }

    thead {
      tr {
        &:first-child {
          th {
            &:first-child {
              position: sticky;
              left: 0;
              z-index: 2;
            }

            &:nth-child(2) {
              position: sticky;
              left: 240px;
              z-index: 2;
            }

            &:last-child {
              position: sticky;
              right: -1px;
              z-index: 2;
            }

            // &:nth-child(3) {
            //   margin-top: -11px;
            // }
          }
        }

        &:last-child {
          th {
            background: #F5F6F6;
          }
        }

        th {
          display: flex;
          justify-content: space-between;

          font-weight: 450;
          font-size: 14px;
          line-height: 16px;
          color: #3B3B41;
          padding: 1rem 0.5rem;
          border-bottom: none;

          &:first-child {
            position: sticky;
            left: 0;
          }

          &:last-child {
            display: flex;
            flex-direction: row-reverse;
            // right: 0;
          }

          &:nth-child(n+3):not(:last-child()) {
            display: flex;
            justify-content: center;
          }

        }
      }
    }

    tbody {
      tr {
        background: white;

        &:nth-child(8),
        &:nth-child(16) {
          background: #ECEDF1;
          margin-top: 24px;
          padding: 0.5rem 0rem;
          border: none;

          td {
            border: none;
          }
        }

        &:nth-child(3),
        &:nth-child(14) {
          background: #ECEDF1;
          padding: 0.5rem 0rem;
          border: none;

          td {
            border: none;
          }
        }

        &:first-child {
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          color: #6F6F77;

          td {
            padding: 1rem 0.5rem;
          }
        }

        &:nth-child(13),
        &:nth-child(19) {
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          color: #6F6F77;
          margin-top: 24px;

          td {
            border-top-width: 1px;
            border-color: #E5E6E6;
            padding: 1rem 0.5rem;
          }
        }

        td {
          &:first-child {
            position: sticky;
            left: 0;
            background: inherit;
          }

          &:nth-child(2) {
            position: sticky;
            left: 240px;
            background: inherit;
          }
        }

      }
    }
  }

  .form-ltp:disabled {
    background-color: inherit !important;
    opacity: 1;
  }

  // =========================
  // WASTE SHORT TERM PLANNING
  // =========================
  &.detail {
    .sub-header {
      .content-right {
        span {
          color: $gray;
          font-size: 13px;
          font-style: italic;
        }

        button {
          width: 96px;
          margin-left: 10px;
        }
      }
    }

    .content-left {
      display: flex;
      align-items: center;

      button {
        background: transparent;
        display: flex;
        align-items: center;
        border: none;
        outline: none;

        &:focus {
          box-shadow: none;
        }
      }

      h1 {
        margin: 0;
        font-size: 21px;
        padding-left: 15px;
      }
    }

    .wrapper-action {
      padding: 10px;
      border-bottom: 1px solid $stroke;
      display: flex;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;

        svg {
          margin-right: 10px;
        }

        h3 {
          font-size: 16px;
          color: $black-dark-20;
          margin: 0;
        }
      }

      .right {
        display: flex;

        .dropdown {
          margin-right: 15px;
        }
      }
    }

    .wrapper-content {
      background: $white;
      min-height: 100vh;

      .title {
        padding: 10px;
        font-size: 14px;
        color: $primary;
        font-weight: 500;
      }

      .form-label {
        color: $gray;
        font-size: 11px;
      }

      .wrapper-section {
        padding: 20px 10px;
        background: $white;
        border-top: 1px solid $stroke;
        border-bottom: 1px solid $stroke;

        .title {}

        .row {
          align-items: center;
        }

        .wrapper-header {
          .col-md-6 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid $stroke;
            min-height: 60px;

            &:first-child {
              border-left: none;
            }

            &:last-child {
              border-right: none;
            }
          }

          .col-md-5 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid $stroke;
            min-height: 60px;
            border-left: none;
          }

          .col-md-7 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid $stroke;
            min-height: 60px;
            border-right: none;
          }

          .left {
            display: flex;
            align-items: center;

            h3 {
              font-size: 14px;
              margin: 0;
              color: $black-dark-20;
              font-weight: 500;
              padding-left: 10px;
            }
          }

          .right {
            display: flex;
            align-items: center;

            h3 {
              margin: 0;
            }

            .ch-header {
              display: flex;
              align-items: center;

              h3 {
                font-size: 20px;
              }

              span {
                display: block;
                font-size: 14px;
                padding-right: 20px;
                padding-left: 20px;
              }
            }
          }
        }

        .total-removal {
          border: 1px solid $stroke;
          border-radius: 4px;
          height: 111px;
          margin: 0 10px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          font-weight: 500;

          .title {
            font-size: 18px;
            padding: 0;
          }

          .total-count {
            font-size: 24px;
          }
        }

        .list-removal {
          background: $bright-gray;
          padding: 10px;
          border-radius: 4px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .item-name {
            font-size: 14px;
            color: $gray;
          }

          .item-count {
            color: $black-dark-20;
            font-size: 14px;
            font-weight: bold;
            margin: 0;
          }
        }

        .wrap-select {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          padding: 3px;

          .item-select {
            width: 20%;
            flex: 0 0 20%;
            padding: 0 8px;
          }
        }
      }

      .accordion {
        .accordion-item {
          border: 0;

          .accordion-header {
            .accordion-button {
              font-weight: bold;
              border-bottom: 1px solid #e5e6e6;
              padding: 25px 10px;

              &:focus {
                border-color: none;
                outline: 0;
                box-shadow: none;
              }

              &:not(.collapsed) {
                background: $white;
                color: $black-dark;
              }
            }
          }

          .accordion-collapse {
            .accordion-body {
              padding: 0;
            }
          }
        }
      }

      .wrapper-table {
        min-height: 140px;
        padding-bottom: 10px;

        .wrap-title {
          background-color: white;
          padding: 25px 10px;
          border-top: 1px solid #e5e6e6;

          >h6 {
            font-weight: 700;
            margin-bottom: 0;
          }
        }

        .col-md-6 {
          padding: 0;
        }

        .empety-data {
          text-align: center;
          color: $gray;
          font-size: 14px;
          display: block;
          padding: 30px 0;
        }

        .wrapper-button {
          height: 50px;
          text-align: center;
        }

        .btn {
          margin-top: 10px;

          &.btn-primary {
            width: 51px;
          }

          &.btn-secondary {
            width: 84px;
          }
        }

        .table {
          margin-bottom: 0;
        }

        .input-row {
          background: $gray-bg;
          padding: 10px 0;

          .col-md-4 {
            padding: 0 10px;
          }

          .ccm-input-wrapper {
            input[type='text'] {
              border-left: none;
            }

            input[type='time' i] {
              // display: none;
            }
          }
        }

        .table-responsive {
          //height: auto;
        }
      }

      .wrap-note {
        padding: 10px 10px 40px;

        >span {
          font-weight: 500;
          font-size: 11px;
          line-height: 13px;
          color: #6f6f77;
          margin-bottom: 13px;
        }

        .input-note {
          min-height: 120px;
          border-radius: 5px;
        }
      }

      .table {
        &.primary {
          td {
            vertical-align: middle;
          }
        }

        tr {
          &:last-child {
            .mid-waste-inpit {
              padding-left: 16px;
            }
          }

          .mid-waste-inpit {
            padding-left: 32px;
          }
        }

        td {
          &.mid-year1 {
            background-color: #ecedf1;
          }
        }
      }

      .ccm-input-wrapper {
        .no-border {
          border-left: none;
        }
      }
    }

    .action-footer {
      padding: 10px;
      display: flex;

      .upload-document {
        span {
          padding-right: 30px;
          font-size: 14px;
          color: $black-dark-20;
        }
      }

      .wrapper-document {
        display: flex;
        flex-wrap: wrap;

        .document {
          padding: 6px 18px;
          border-radius: 50px;
          border: 1px solid $stroke;
          margin-left: 10px;
          font-size: 14px;
          color: $gray;
          cursor: pointer;

          svg {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.reason {
  .massage {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    margin-bottom: 13px;
  }

  .input-reason {
    min-height: 120px;
    border-radius: 5px;
  }
}

.expand-button {
  width: 20px;
  height: 20px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.expand-button:hover {
  background-color: #f0f0f0;
}

.collapse-button {
  width: 20px;
  height: 20px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.collapse-button:hover {
  background-color: #f0f0f0;
}

.expand-button-span {
  padding-left: 4px;
  padding-right: 2px;
}
