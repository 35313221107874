// .container {
//   max-width: 1200px;
//   margin: auto;
// }

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-item-center {
  display: flex;
  align-items: center;
}

.flex-row-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.abs-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.zi-100 {
  z-index: 100;
}

.row {
  margin: 0;
}
