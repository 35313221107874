.log-status {
  min-height: 60vh;
  overflow: hidden;
  padding: 10px 0;
  border-left: 2px solid $stroke;
  border-Bottom: 2px solid $stroke;

  &__title {
    font-size: 1.5rem;
    padding-left: 10px;
    color: $black;
    margin-bottom: 12px;
  }

  &__wrapper {
    padding-left: 10px;

    .date {
      font-size: 12px;
      font-weight: 500;
      color: #6B6B7D;
      font-weight: bold;
      position: relative;

      &::after {
        content: ' ';
        display: block;
        position: relative;
        height: 12px;
        width: 12px;
        background-color: #fff;
        border: 1px solid #3B3B41;
        border-radius: 100%;
        left: -17px;
        top: -16px;
      }
    }

    .content {
      color: #87879F;
      font-size: 13px;
    }
  }

  &__status {
    width: fit-content;
    border-radius: 5px;
    padding: 3px 10px;
    margin-right: 8px;
    height: 28px;
    line-height: 18px;
    font-size: 13px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    &.add {
      background: #DFEFFF;
      color: #047AFF;
    }
    &.delete {
      background: rgba(255, 0, 0, 0.12);
      color: #E91D28;
    }

    &.edit {
      background: #F5F6F6;
      color: #6F6F77;
    }

    &.ditayangkan,
    &.done {
      background: rgba(21, 165, 95, 0.12);
      color: $green-light;
    }

    &.menunggu_persetujuan,
    &.waiting_approval {
      background: rgba(222, 136, 19, 0.12);
      color: $orange-dark;
    }

    &.diarsipkan,
    &.draft,
    &.tidak_ditayangkan {
      background: $gray-bg;
      color: $gray;
    }

    &.rejected,
    &.dibatalkan,
    &.ditolak,
    &.inactive,
    &.unpublish {
      background: rgba(255, 0, 0, 0.12);
      color: $red;
    }

    &.disetujui {
      background: $blue-bg-light;
      color: $blue;
    }
  }
}
