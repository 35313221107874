/*
    this sheet is only for global variables that used in folder styles
    used for default colors or size
    need to import in global.scss file to using these variables in a sheet
*/


// palette
$black-dark-30: #3B3B41;
$black-dark-20: #3E3E49;
$black-dark-10: #2d2627;
$black-dark: #000000;
$black-darker: #2b333a;
$black-light: #313b44;
$black-shadow: rgba(0, 0, 0, 0.05);
$black: #333333;
$blue-dark: #0066cc;
$blue-extra-dark: #05324b;
$blue-bg-light: rgba(0, 122, 255, 0.12);
$blue-bg: rgba(0, 122, 255, 0.12);
$blue-hover: #0161C7;
$blue-link: #2F80ED;
$blue: #007AFF;
$gray-bg: #F5F6F6;
$gray-bg-10: #F5F5F6;
$bright-gray: #F5F6FA;
$gray-bg-dark: #e9ecef;
$gray-bg-darken: #F5F5F5;
$gray-dark-10: #6F6F77;
$gray-dark-20: #ECEDF1;
$gray-dark-30: #E5E6E6;
$gray-dark: #515154;
$gray-light: #b9b9b9;
$gray-lighter: #fafafa;
$gray: #6B6B7D;
$green-dark: #357d38;
$green-gold: #9fb65f;
$green-pistal: #67c0b0;
$green-light: #15a55f;
$green-bg: rgba(34, 156, 89, 0.12);
$green: #229C59;
$orange: #fca250;
$orange-dark: #DE8813;
$orange-darken: #F3C156;
$orange-bg: rgba(222, 136, 19, 0.12);
$mari-gold: #f79c211f;
$purple-light: #b2c8ff;
$purple: #6848c3;
$purple-bg: rgba(104, 72, 195, 0.12);
$red-bg: rgba(211, 46, 54, 0.12);
$red-dark: #e91d28;
$red-extra-dark: #c02828;
$red-light: #ffeeee;
$red: #ED5451;
$shadow: rgba(0, 0, 0, 0.16);
$violet: #a016a3;
$white: #ffffff;
$gray-border: #E5E6E6;

// Colors
$primary: #FF0C2B;
// $primary: #4A47EC;
$secondary: $gray-bg;
$scrollbar: #d9dadf;
$stroke: #e1e2ea;
$border-color: $stroke;

:root {
  --black-dark-10: #2d2627;
  --black-dark: #000000;
  --black-light-10: #4c5a67;
  --black-light: #313b44;
  --black: #333333;
  --blue-dark: #0066cc;
  --blue-extra-dark: #05324b;
  --blue-light: #0087d2;
  --blue-link: #2f80ed;
  --blue: #007aff;
  --gray-bg: #f5f6fa;
  --gray-bg-10: #F5F5F6;
  --gray-dark: #515154;
  --gray-light-10: #fafafa;
  --gray-light: #b9b9b9;
  --gray: #858a8f;
  --green-dark: #357d38;
  --green-gold: #9fb65f;
  --green-pistal: #67c0b0;
  --green-light: #15a55f;
  --green: #1db553;
  --light-orange: #fca250;
  --orange-dark: #de8813;
  --orange: #ff912c;
  --purple-light: #b2c8ff;
  --purple: #6848c3;
  --red-bg: rgba(255, 0, 0, 0.08);
  --red-dark: #e91d28;
  --red-extra-dark: #c02828;
  --red-light: #ffeeee;
  --red: #ff0000;
  --secondary: #f5f6fa;
  --teal: #35d0da;
  --violet: #a016a3;
  --white: #ffffff;
  --primary: #4A47EC;
  --gray-border: #E5E6E6;


  --primary: var(--primary);
  --light: var(--gray);
  --light-bg: var(--gray-bg);
  --info: var(--blue);
  --stroke: #e1e2ea;
  --border-color: var(--stroke);
}

// Define border properties
$border-color: $stroke  !default;
$border-radius: 0.25rem !default;
$border-radius-lg: 0.3rem !default;
$border-radius-sm: 0.25rem !default;
$border-radius-xs: 0.125rem !default;
$border-radius-circle: 50% !default;
$border-radius-round: 50px !default;
$border-width: 1px !default;
