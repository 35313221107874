.ccm-crushing-actual {
  $green: #36b577;
  $yellow: #FFB236;
  $red: #ed5451;
  $blue: #2196f3;
  $purple: #9b51e0;

  .content-left {
    .status {
      margin-left: 20px;
      font-size: 14px;
      color: $gray;
      border: 1px solid $stroke;
      border-radius: 4px;
      padding: 6px 8px;
      margin-bottom: 0;
      font-weight: 500;
    }
  }

  &__header {
    .search-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border: 1px solid #e1e2ea;
      padding: 8px 15px;
      align-items: center;

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        margin: 0;
      }
    }
  }

  &__wrapper {
    width: 100%;
    // height: 56px;
    // background: $bright-gray;

    .wrapper-search {
      width: 100%;
      height: 56px;
      background: $bright-gray;

      display: flex;
      align-items: center;
      padding: 0 15px;

      .input-group {
        width: 256px;

        span {
          padding-right: 5px;
        }

        input {
          border-left: none;
        }
      }
    }

    .wrapper-action {
      padding: 0 10px;
      width: 100%;
      height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h1 {
        font-size: 16px;
        color: $black-dark-30;
      }

      .dropdown {
        margin-right: 15px;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    border: 1px solid #e1e2ea;
    border-radius: 4px;
    align-items: center;
    height: 48px;
    padding-left: 15px;

    p {
      margin-left: 10px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 0;
    }
  }

  &.detail {
    .sub-header {
      .content-right {
        span {
          color: $gray;
          font-size: 13px;
          font-style: italic;
        }

        button {
          width: 96px;
          margin-left: 10px;
        }
      }
    }

    .content-left {
      display: flex;
      align-items: center;

      button {
        background: transparent;
        display: flex;
        align-items: center;
        border: none;
        outline: none;

        &:focus {
          box-shadow: none;
        }
      }

      h1 {
        margin: 0;
        font-size: 21px;
        padding-left: 15px;
      }
    }

    .wrapper-action {
      padding: 20px 10px;
      border-bottom: 1px solid $stroke;
      display: flex;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;

        svg {
          margin-right: 10px;
        }

        h3 {
          font-size: 16px;
          color: $black-dark-20;
          margin: 0;
        }
      }

      .right {
        display: flex;

        .dropdown {
          margin-right: 15px;
        }
      }
    }

    .wrapper-input {
      padding: 20px 10px;

      label {
        font-size: 11px;
        color: $gray-dark-10;
      }

      .form-control:disabled {
        background-color: $bright-gray;
      }
    }

    .wrapper-content {
      min-height: 100vh;

      .title {
        padding: 10px;
        font-size: 14px;
        color: $primary;
        font-weight: 500;
        border-bottom: 1px solid $stroke;
      }

      .form-label {
        color: $gray;
        font-size: 11px;
      }

      .wrapper-section {
        background: $white;
        margin-bottom: 8px;
        border-top: 1px solid $stroke;
        border-bottom: 1px solid $stroke;

        .row {
          align-items: center;
        }

        .wrapper-header {
          .col-md-6 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid $stroke;
            min-height: 60px;

            &:first-child {
              border-left: none;
            }

            &:last-child {
              border-right: none;
            }
          }

          .col-md-5 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid $stroke;
            min-height: 60px;
            border-left: none;
          }

          .col-md-7 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid $stroke;
            min-height: 60px;
            border-right: none;
          }

          .left {
            display: flex;
            align-items: center;

            h3 {
              font-size: 14px;
              margin: 0;
              color: $black-dark-20;
              font-weight: 500;
              padding-left: 10px;
            }
          }

          .right {
            display: flex;
            align-items: center;

            h3 {
              margin: 0;
            }

            .ch-header {
              display: flex;
              align-items: center;

              h3 {
                font-size: 20px;
              }

              span {
                display: block;
                font-size: 14px;
                padding-right: 20px;
                padding-left: 20px;
              }
            }
          }
        }

        .total-removal {
          border: 1px solid $stroke;
          border-radius: 4px;
          height: 111px;
          margin: 0 10px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          font-weight: 500;

          .title {
            font-size: 18px;
            padding: 0;
          }

          .total-count {
            font-size: 24px;
          }
        }

        .list-removal {
          background: $bright-gray;
          padding: 10px;
          border-radius: 4px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .item-name {
            font-size: 14px;
            color: $gray;
          }

          .item-count {
            color: $black-dark-20;
            font-size: 14px;
            font-weight: bold;
            margin: 0;
          }
        }
      }

      .wrapper-table {
        min-height: 140px;
        padding-bottom: 10px;

        .col-md-6 {
          padding: 0;
        }

        .empety-data {
          text-align: center;
          color: $gray;
          font-size: 14px;
          display: block;
          padding: 30px 0;
        }

        .wrapper-button {
          height: 50px;
          text-align: center;
        }

        .btn {
          margin-top: 10px;

          &.btn-primary {
            width: 51px;
          }

          &.btn-secondary {
            width: 84px;
          }
        }

        .table {
          margin-bottom: 0;
        }

        .input-row {
          background: $gray-bg;
          padding: 10px 0;

          .col-md-4 {
            padding: 0 10px;
          }

          .ccm-input-wrapper {
            input[type='text'] {
              border-left: none;
            }
          }
        }
      }

      .table {
        &.primary {
          td {
            vertical-align: middle;
          }
        }
      }

      .ccm-input-wrapper {
        .no-border {
          border-left: none;
        }
      }
    }

    .action-footer {
      padding: 10px;
      display: flex;

      .upload-document {
        span {
          padding-right: 30px;
          font-size: 14px;
          color: $black-dark-20;
        }
      }

      .wrapper-document {
        display: flex;
        flex-wrap: wrap;
        padding: 6px 18px;
        border-radius: 50px;
        border: 1px solid #e1e2ea;
        margin-left: 10px;
        font-size: 14px;
        color: #6b6b7d;
        cursor: pointer;
        align-items: center;

        svg {
          margin-left: 15px;
        }
      }
    }
  }

  // override react-calendar-timeline style
  .react-calendar-timeline {
    .rct {
      &-calendar-header {
        border: none;

        //hour needle
        .date-header-row {
          div {
            bottom: 16px;

            &::after {
              content: '|';
              position: absolute;
              top: 20px;
              left: 50%;
              font-size: 12px;
              // custom
              // content: '';
              // position: absolute;
              // bottom: -14px;
              // left: 50%;
              // width: 2px;
              // height: 12pt;
              // background: $black-dark-30;
              // border-radius: 2px;
            }
          }
        }
      }

      &-dateHeader {
        border: none;

        &-primary {
          // cursor: pointer; // Default
          cursor: default;
          color: $gray-dark-10;
          // border: none !important;
          border-bottom: 2px solid $gray-border !important;
          font-size: 14px;
          font-weight: 500;

          .custom-date-header {
            display: flex;
            justify-content: space-around;
            width: 30%;
            user-select: none;
            height: 100%;

            .date {
              display: flex;
              min-width: 150px;
              align-items: center;
              justify-content: center;
              z-index: 1;
              background-color: #f5f6f6;

              &.inactive {
                color: #a9a9a9;
              }

              .shift {
                padding-left: 8px;
                text-transform: capitalize;
              }
            }

            &:after {
              content: '';
              position: absolute;
              top: 0;
              left: 50%;
              width: 1px;
              height: 100%;
              background-color: #e5e6e6;
            }
          }
        }
      }

      &-header {
        &-root {
          background: #f5f6f6;
          border-bottom: 1px solid $gray-border !important;

          .sidebar-header {
            background: #fff;
            border-right: 1px solid $gray-border !important;

            .sidebar-header-content {
              display: flex;
              width: 100%;
              flex: 1;
              height: 40px;
              background: #f5f6f6;
              border-bottom: 2px solid $gray-border !important;
              align-items: center;
              justify-content: center;

              // padding: 0 16px;
              .sidebar-header-shift {
                margin: auto;
                user-select: none;
                font-size: 14px;
              }

              .sidebar-header-nav {
                display: flex;
                align-items: center;
                padding: 0 16px;
                height: 100%;
                cursor: pointer;

                &.rotate {
                  transform: rotate(180deg);
                }

                &.disabled {
                  cursor: not-allowed;

                  svg {
                    path {
                      fill: #b5b6b6;
                    }
                  }
                }
              }
            }

            .sidebar-table-header {
              display: grid;
              grid-template-columns: 10fr 6fr 8fr 8fr 24fr;
              font-weight: 500;
              font-size: 12px;
              line-height: 14px;
              color: #3b3b41;

              &.jetty {
                grid-template-columns: 8fr 8fr 8fr 8fr;

                &>div:last-child {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }

              &.rom-excavator {
                grid-template-columns: 10fr 6fr 6fr 6fr 6fr 16fr;
              }

              &>div {
                border-left: 1px solid #e5e6e6;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 48px;
                text-align: center;
              }

              &>div:last-child {
                display: inline-grid;
                grid-template-rows: 1fr 1fr;
                justify-content: unset;
                align-items: unset;

                &>div {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }

                .performance {
                  display: grid;
                  grid-template-columns: repeat(4, 1fr);

                  >div {
                    border-left: 1px solid #e5e6e6;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #f3f7fc;
                    font-weight: 400;
                  }
                }
              }
            }
          }
        }
      }

      &-horizontal-lines {
        .rct-hl-odd {
          background: none !important;
          border-bottom: 1px solid $gray-border !important;
        }

        .rct-hl-even {
          border-bottom: 1px solid $gray-border !important;
        }
      }

      &-vertical-lines {
        .rct-vl {
          border: none !important;

          &.rct-day-6 {
            z-index: 1 !important;
            /*
              refer to bug: timeline-1
              change default color that on default
              it can show the different between weekend and weekday
            */
            background-color: rgba(0, 0, 0, 0) !important;
          }

          &.rct-day-0 {
            z-index: 1 !important;
            /*
              refer to bug: timeline-1
              change default color that on default
              it can show the different between weekend and weekday
            */
            background-color: rgba(0, 0, 0, 0) !important;
          }
        }
      }

      &-item {
        // z-index: 80; // Default
        z-index: 1 !important;

        .rct-item-content {
          width: 100%;
          text-align: center;
        }

        &.phase {
          font-size: 14px !important;
          border-radius: 8px;
          border: none !important;
          display: flex;
          justify-content: center;
          font-weight: 500;
          // top: 12px !important;

          .title {
            // display: flex;
            // width: 100%;
            align-items: center;
            // justify-content: center;
            white-space: nowrap;
            overflow: hidden;
            margin: 0 12px;
            text-overflow: ellipsis;
          }

          &.work {
            color: #ffffff !important;
            background: #17CE10 !important;
          }

          &.without-cargo {
            color: #ffffff !important;
            background: #116519 !important;
          }

          &.maintenance {
            color: #ffffff !important;
            background: #FFB236 !important;
          }

          &.end-of-shift {
            color: $blue !important;
            background: #2196f31f !important;
          }

          &.delay {
            color: #ffffff !important;
            // background: #e42f1b !important;
            background: #FE3636 !important;
          }

          &.pre-loading {
            color: #ffffff !important;
            background: #C9C9D1 !important;
          }

          &.post-loading {
            color: #ffffff !important;
            background: #007AFF !important;
          }

          &.gray {
            color: #ffffff !important;
            background: #C9C9D1 !important;
          }
        }

        &.title {
          font-size: 10px !important;
          border-radius: 8px;
          border: none !important;
          display: flex;
          justify-content: center;
          font-weight: 500;
          color: #3B3B41 !important;
          background: #F5F6F6 !important;

          &.transshipment {
            background: #C9FBC6 !important;
          }
        }
      }

      &-sidebar {
        border-right: 1px solid $gray-border !important;
        box-shadow: 1px 2px 6px 0px #b0b0b04d;

        &-row {
          display: flex;
          flex-direction: column;
          line-height: 1.2 !important;
          justify-content: center;
          border-bottom: 1px solid $gray-border !important;
          padding: 0 !important;
          row-gap: 4px;

          &.rct-sidebar-row-odd {
            background: none !important;
          }

          .sidebar-group-heading {
            .sidebar-group-title {
              color: $black-dark-30;
              font-weight: 600;
              font-size: 12pt;
            }
          }

          .sidebar-group-grid {
            display: grid;
            grid-template-columns: 10fr 6fr 8fr 8fr repeat(4, 6fr);

            &.jetty {
              grid-template-columns: 8fr 8fr 8fr 8fr;
            }

            &.rom-excavator {
              grid-template-columns: 10fr 6fr 6fr 6fr 6fr repeat(4, 4fr);
            }

            span {
              color: #3b3b41;
              font-size: 9.5pt;

              &.danger {
                color: $red;
              }

              &.safe {
                color: $green;
              }

              &.warning {
                color: $yellow;
              }

              &.purple {
                color: $purple;
              }

              &.blue {
                color: #007AFF;
              }
            }

            &>div {
              white-space: normal;
              text-align: center;
              overflow: hidden;
              font-weight: 400;
              font-size: 12px;
              color: #3b3b41;
              display: flex;
              align-items: center;
              justify-content: center;
              word-break: break-all;
              padding: 2px;
            }
          }
        }
      }

      &-outer {
        border-bottom: 1px solid $gray-border !important;
        overflow: visible;
      }

      &-scroll {
        overflow: visible;
        overflow-x: clip;

        &>div {
          left: -100% !important;
        }
      }

      &-items {
        .custom-tooltip {
          border-radius: 8px;
          background: #3b3b41;
          line-height: 11pt;
          margin-top: -8px;

          &::after {
            border-bottom-color: #3b3b41 !important;
          }

          .container {
            font-size: 10pt;
            padding: 0;

            .title {
              padding: 6px 12px;
            }

            .duration {
              margin-bottom: 4px;
            }
          }
        }
      }
    }
  }

  .today-marker:before {
    display: block;
    content: attr(data-date);
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -100%);
    background: #ff0c2b;
    border-radius: 4px;
    font-size: 14px;
    color: #ffffff;
    padding: 5.5px;
    width: max-content;
  }

}

.log-status.dashboard {
  padding: 0;
  min-height: auto;

  .log-status__title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
  }

  .content-modal {
    padding: 0 10px;

    .row {
      .col-md-2 {
        padding-left: 0;
        padding-right: 0;
      }

      .col-md-4 {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .circle-danger {
    width: 24px;
    height: 12px;
    background-color: $red;
    border-radius: 20px;
  }

  .circle-safe {
    width: 24px;
    height: 12px;
    background-color: $green;
    border-radius: 20px;
  }

  .circle-warning {
    width: 24px;
    height: 12px;
    background-color: $yellow;
    border-radius: 20px;
  }

  .circle-purple {
    width: 24px;
    height: 12px;
    background-color: $purple;
    border-radius: 20px;
  }

  .circle-blue {
    width: 24px;
    height: 12px;
    background-color: $blue;
    border-radius: 20px;
  }
}

.wrapper-modal {
  height: 100%;

  .wrapper-content-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 0px;
    background: #F5F6F6;
    border-radius: 12px;
    margin-bottom: 16px;

    .title {
      font-size: 14px;
      line-height: 16px;
      color: $primary;
    }
  }

  .wrapper-form {
    padding: 16px 0px;
  }
}

.modal-footer-barge {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0rem 0.75rem 0.75rem 0.75rem;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.barge-date-content {
  width: 50%;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border: 1px solid #E5E6E6;

  .arrow-left {
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;

      svg {
        path {
          fill: #b5b6b6;
        }
      }
    }
  }

  .arrow-right {
    cursor: pointer;
    transform: rotate(180deg);

    &.disabled {
      cursor: not-allowed;

      svg {
        path {
          fill: #b5b6b6;
        }
      }
    }
  }

  .date-shift-title {
    color: #6F6F77;
    font-weight: 500
  }
}
