.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__time-list-item--selected {
  background: $primary !important;
}

.react-datepicker__header {
  background: transparent !important;
}

.react-datepicker {
  font-family: Normal;
  display: flex;

  &-popper {
    z-index: 3;
  }
}

.ccm-date-picker {
  .icon-box {
    z-index: 1;
  }
  .react-datepicker-popper {
    z-index: 10;
  }
  .react-datepicker__triangle {
    display: none
  }
  input {
    @extend .form-control;
    padding-left: 2.5rem !important;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__action-button {
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__text {
    margin-bottom: 0;
  }
  &.custom-time {
    .react-datepicker {
      display: inline-block;
    }
  }
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  padding-left: 7px;
}

.ccm-input-wrapper {
  &.custom-time {
    .react-datepicker {
      display: inline-block;
    }
  }
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__ {

}

.react-datepicker__navigation-icon {
    position: relative;
    top: 5px;
    font-size: 20px;
    width: 0;
}

.react-datepicker__year-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 205px;
}

.react-datepicker__input-time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 160px;
  margin: 0px;
  text-align: center;
}

.react-datepicker__input-time-container .react-datepicker-time__caption {
  color: #000;
  font-size: .944rem;
  font-weight: 700;
  border-bottom: 1px solid #aeaeae;
  width: 100%;
  padding: 5px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container {
  margin-top: 10px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  margin-left: 0px;
}
