.table-responsive {
  min-height: 40vh;
  height: 60vh;
}

.table-custom-height {
  height: auto !important;
  min-height: 0 !important;
}

.table {

  // ==================
  // STYLING TH/TD DEFAULT
  // ==================
  th {
    font-size: 14px;
    font-weight: 400;
    color: $gray-dark-10;
    // width: auto !important;
  }

  td {
    color: $black-dark-20;
    font-size: 14px;
  }

  tbody td:first-child:has(.l-border-red) {
    border-left: 5px solid red;
  }

  // ==================
  // STYLING TABLE MAX WIDTH
  // ==================
  &.max-width {
    width: max-content !important;

    th {
      padding-right: 0px !important;
    }
  }

  // ==================
  // STYLING TABLE NO FLEX
  // ==================
  &.no-flex {
    tr {
      display: table-row !important;
      flex: none !important;
    }
  }

  // ==================
  // STYLING TABLE PRIMARY
  // ==================
  &.primary {
    th {
      background: $gray-bg;
      border-top: 1px solid $stroke;
      border-bottom: 1px solid $stroke;
      position: relative;
      padding-right: 30px;
    }

    td {
      input {
        color: $black-dark-20;
        font-size: 14px;
        border: none;

        &.has-error {
          border: 1px solid red;
          color: red;
        }
      }

      .form-check-input {
        opacity: 1 !important;
      }

      .form-check-label {
        opacity: 1 !important;
      }
    }

    .sort {
      position: absolute;
      right: 3px;
      padding: 1px 6px;
      background: $stroke;

      &.active {
        background: $primary;
        border-radius: 2px;
        padding: 1px 0;
      }
    }

    .action {
      display: flex;
      justify-content: space-around;

      button {
        font-size: 14px;
        padding: 3px 7px;
      }

      :disabled {
        margin-right: 10px !important;
        margin-left: 2px;

        &:last-child {
          margin-right: 0 !important;
        }

        &.btn {
          opacity: 1;
        }
      }
    }

    .masterData-dropdown.has-error {
      border: 1px solid red;
      border-radius: 4px;
    }

    .masterData-dropdown.title {
      button {
        color: #e5e6e6 !important;
        font-size: 14px;
      }
    }

    .masterData-dropdown-draft.title {
      button {
        color: #e5e6e6 !important;
        font-size: 14px;
      }
    }

    .masterData-dropdown.dropdown {
      background: transparent;
      border: none;
      font-size: 14px;
      color: $gray;
      padding: 0;

      button {
        padding: 0;
        text-align: left;
        min-height: 20px;
        background: transparent !important;
      }

      .dropdown-toggle {
        &::after {
          background: none;
        }
      }
    }

    .masterData-dropdown-draft.dropdown {
      background: transparent;
      border: none;
      font-size: 14px;
      color: $gray;
      padding: 0;

      button {
        padding: 0;
        color: #007aff;
        text-align: left;
        min-height: 20px;
        background: transparent !important;
      }

      .dropdown-toggle {
        &::after {
          background: none;
        }
      }
    }

    .distance-dropdown {
      &.has-error {
        border: 1px solid red;

        div {
          color: red;
        }
      }
    }

    .distance-dropdown.dropdown {
      background: transparent;
      border: none;
      font-size: 14px;
      color: $gray;
      padding: 0;

      button {
        padding: 0 0.5rem !important;
        text-align: left;
        min-height: 20px;
        background: transparent !important;
      }

      .dropdown-toggle {
        &::after {
          padding: 0 10px !important;
          width: auto !important;
          background-color: #e5e6e6;
        }
      }
    }

    // .dropdown {
    //   background: transparent;
    //   border: none;
    //   font-size: 14px;
    //   color: $gray;
    //   padding: 0;

    //   button {
    //     padding: 0;
    //     text-align: center;
    //     min-height: 20px;
    //     background: transparent !important;
    //   }

    // }
  }

  // ==================
  // STYLING TABLE EXPANDED
  // ==================
  &.expanded {
    thead {
      tr {
        &:first-child {
          th {
            border: 1px solid $stroke;
            border-left: none;
          }
        }

        th {
          background: $white;
          text-align: center;
          white-space: nowrap;

          &:first-child {
            border-right: none;

            span {
              visibility: hidden;
            }
          }
        }
      }
    }

    tbody {
      .expanded {
        background: $gray-dark-30;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          height: 100%;
          width: 3px;
          background: $red;
          left: 0;
        }

        td {
          background: $gray-dark-30;
        }
      }

      .false {
        td {
          background: $white;
        }
      }

      input {
        background: transparent;
        border: none;
        font-size: 14px;
        color: $gray;
        text-align: center;
      }

      .dropdown {
        background: transparent;
        border: none;
        font-size: 14px;
        color: $gray;
        padding: 0;

        button {
          padding: 0;
          text-align: center;
          min-height: 20px;
          background: transparent !important;
        }

        .dropdown-toggle {
          &::after {
            background: none;
          }
        }

        .dropdown-menu {
          position: relative !important;
          transform: translate3d(0px, 5.25px, 0px) !important;
        }
      }

      tr {
        td {
          text-align: center;
        }
      }
    }

    .border-rigt {
      border-right: 1px solid $stroke;
    }
  }

  // ==================
  // STYLING TABLE PLANNING
  // ==================
  &.table-planning {
    tr {
      td {
        input {
          border: none;
          padding: 0;
          -webkit-appearance: none;

          &:disabled {
            background: transparent;
          }
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type='number'] {
          -moz-appearance: textfield;
        }
      }
    }

    thead {
      tr {
        &:last-child {
          th {
            background: $gray-dark-20;
          }
        }

        th {
          display: flex;
          justify-content: space-between;

          &:first-child {
            position: sticky;
            left: 0;
          }

          &:nth-child(2) {
            position: sticky;
            left: 200px;
          }

          .delete-column {
            text-align: center;
            width: 20px;
            height: 20px;
            border-radius: 100%;

            &:hover {
              background: #d7d8d8;
              cursor: pointer;
            }
          }
        }

        // .sticky-header {
        //   flex: none !important;
        //   min-width: 0px !important;
        //   width: 150px !important;
        //   position: relative;
        // }
      }

      th:only-child {
        position: relative !important;

        .sticky-table-type {
          position: sticky;
          left: 5px !important;
        }
      }
    }

    tbody {
      tr {
        // &:last-child {
        //   td {
        //     &:first-child {
        //       padding-left: 10px !important;

        //     }
        //   }
        // }

        td {
          &:first-child {
            position: sticky;
            left: 0;
            background: $white;
          }

          &:nth-child(2) {
            position: sticky;
            left: 200px;
            background-color: white;
          }
        }
      }
    }
  }

  &.table-planning-coal {
    &.haul {
      th {
        display: none !important;
      }
    }

    tr {
      td {
        input {
          border: none;
          padding: 0;
          -webkit-appearance: none;

          &:disabled {
            background: transparent;
          }
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type='number'] {
          -moz-appearance: textfield;
        }
      }
    }

    thead {
      tr {
        &:last-child {
          th {
            &:first-child {
              background: $gray-dark-20;
            }
          }
        }
      }

      th:only-child {
        position: relative !important;

        .sticky-table-type {
          position: sticky;
          left: 5px !important;
        }
      }
    }

    // tbody {
    //   tr {
    //     &:last-child {
    //       td {
    //         &:first-child {
    //           padding-left: 10px !important;
    //         }
    //       }
    //     }
    //   }
    // }
  }

  &.table-payload {
    tr {
      td {
        input {
          border: none;
          padding: 0;
          -webkit-appearance: none;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type='number'] {
          -moz-appearance: textfield;
        }
      }
    }

    thead {
      tr {
        &:last-child {
          th {
            background: $gray-dark-20;
          }
        }

        th {
          display: flex;
          justify-content: space-between;

          &:first-child {
            position: sticky;
            left: 0;
          }

        }
      }
    }

    tbody {
      tr {
        td {
          &:first-child {
            position: sticky;
            left: 0;
            background: $white;
          }
        }
      }
    }
  }

  // ==================
  // STATUS ON TABLE
  // ==================
  .status {
    border-radius: 4px;
    padding: 4px 6px;

    &.done,
    &.active,
    &.going {
      background: rgba(21, 165, 95, 0.12);
      color: $green-light;
    }

    &.waiting {
      background: rgba(222, 136, 19, 0.12);
      color: $orange-dark;
    }

    &.diarsipkan,
    &.draft,
    &.tidak_ditayangkan,
    &.finished {
      background: $gray-bg;
      color: $gray;
    }

    &.rejected,
    &.ditolak,
    &.unpublish {
      background: rgba(255, 0, 0, 0.12);
      color: $red;
    }

    &.sent {
      background: $blue-bg-light;
      color: $blue;
    }
  }

  // ==================
  // TABLE HEADER STICKY
  // ==================
  thead {
    &.sticky {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }

  &.table-production {
    thead {
      &.sticky {
        z-index: 2;
      }

      tr {
        &:first-child {
          th {
            &:first-child {
              position: sticky;
              left: 0;
              background: $white;
            }
            &:nth-child(2) {
              position: sticky;
              left: 50px;
              background: $white;
            }
          }
        }

        &:last-child {
          // th {
          //   background: $gray-dark-20;
          // }

          th {
            // display: flex;
            // justify-content: space-between;
            // justify-content: center;

            &:first-child {
              position: sticky;
              left: 0;
              background: $white;
            }

            &:nth-child(2) {
              position: sticky;
              left: 50px;
              background: $white;
            }
            &:nth-child(3) {
              position: sticky;
              left: 100px;
              background: $white;
            }
            &:nth-child(4) {
              position: sticky;
              left: 170px;
              background: $white;
            }
            &:nth-child(5) {
              position: sticky;
              left: 370px;
              background: $white;
            }
          }
        }

      }

      th:only-child {
        position: relative !important;

        .sticky-table-type {
          position: sticky;
          left: 5px !important;
        }
      }
    }

    tbody {
      tr {
        td {
          &:first-child {
            position: sticky;
            left: 0;
            z-index: 1;
          }

          &:nth-child(2) {
            position: sticky;
            left: 50px;
            z-index: 1;
          }
          &:nth-child(3) {
            position: sticky;
            left: 100px;
            z-index: 1;
          }
          &:nth-child(4) {
            position: sticky;
            left: 170px;
            z-index: 1;
          }
          &:nth-child(5) {
            position: sticky;
            left: 370px;
            z-index: 1;
          }
        }
      }
    }

  }

  &.table-production-detail {
    thead {
      &.sticky {
        z-index: 2;
      }

      tr {
        &:first-child {
          th {
            &:first-child {
              position: sticky;
              left: 0;
              background: $white;
            }
            &:nth-child(2) {
              position: sticky;
              left: 50px;
              background: $white;
            }
          }
        }

        &:last-child {
          th {
            &:first-child {
              position: sticky;
              left: 0;
              background: $white;
            }

            &:nth-child(2) {
              position: sticky;
              left: 50px;
              background: $white;
              padding: 0px;
            }
            &:nth-child(3) {
              position: sticky;
              left: 50px;
              background: $white;
            }
            &:nth-child(4) {
              position: sticky;
              left: 120px;
              background: $white;
            }
            &:nth-child(5) {
              position: sticky;
              left: 320px;
              background: $white;
            }
          }
        }

      }

      th:only-child {
        position: relative !important;

        .sticky-table-type {
          position: sticky;
          left: 5px !important;
        }
      }
    }

    tbody {
      tr {
        td {
          &:first-child {
            position: sticky;
            left: 0;
            z-index: 1;
          }

          &:nth-child(2) {
            position: sticky;
            left: 50px;
            z-index: 1;
            padding: 0px;
          }
          &:nth-child(3) {
            position: sticky;
            left: 50px;
            z-index: 1;
          }
          &:nth-child(4) {
            position: sticky;
            left: 120px;
            z-index: 1;
          }
          &:nth-child(5) {
            position: sticky;
            left: 320px;
            z-index: 1;
          }
        }
      }
    }
  }

}

.table> :not(:first-child) {
  border-top: none;
}

// ==================
// STYLING DEFAULT PAGINATION
// ==================
.wrapper-pagination {
  padding: 0 10px 40px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-bottom: 30px;

  .pagination {
    align-items: center;

    &-prev,
    &-next {
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
        opacity: 0.3;
      }

      background-color: $gray-bg;
    }

    &-page {
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $gray-light;
      font-size: 14px;
      text-align: center;
      cursor: pointer;

      &.active {
        color: $primary;
      }
    }
  }

  .option-select {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $gray;

    .wrapper-select {
      background-color: $gray-bg-darken;
      border-radius: 4px;

      svg {
        position: relative;
        left: -10px;
      }
    }

    select {
      width: 60px;
      height: 40px;
      border: none;
      border-radius: 4px;
      background: transparent;
      padding-left: 10px;

      position: relative;
      -webkit-appearance: none;
      -moz-appearance: none;

      &:focus-visible {
        border: none;
        outline: none;
      }
    }
  }
}

.header-cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.expand-button,
.collapse-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-left: 5px;
}

.expand-button.hidden,
.collapse-button.hidden {
  display: none;
}

.expand-button:hover,
.collapse-button:hover {
  color: #007bff;
}
