//border
.border         { border: $border-width solid $border-color !important; }
.border-top     { border-top: $border-width solid $border-color !important; }
.border-right   { border-right: $border-width solid $border-color !important; }
.border-bottom  { border-bottom: $border-width solid $border-color !important; }
.border-left    { border-left: $border-width solid $border-color !important; }

.border-0        { border: 0 !important; }
.border-top-0    { border-top: 0 !important; }
.border-right-0  { border-right: 0 !important; }
.border-bottom-0 { border-bottom: 0 !important; }
.border-left-0   { border-left: 0 !important; }

.rounded {
  border-radius: $border-radius !important;
}
.rounded-top {
  border-top-left-radius: $border-radius !important;
  border-top-right-radius: $border-radius !important;
}
.rounded-right {
  border-top-right-radius: $border-radius !important;
  border-bottom-right-radius: $border-radius !important;
}
.rounded-bottom {
  border-bottom-right-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;
}
.rounded-left {
  border-top-left-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}
.br-2 {
  border-radius: 2px !important;
}
.br-3 {
  border-radius: 3px !important;
}
.br-4 {
	border-radius: 4px !important;
}
.br-5 {
  border-radius: 5px !important;
}
.br-6 {
  border-radius: 6px !important;
}
.br-8 {
	border-radius: 8px !important;
}
.br-12 {
	border-radius: 12px !important;
}
.br-28 {
  border-radius: 28px !important;
}
.br-32 {
	border-radius: 32px !important;
}
.br-34 {
  border-radius: 34px !important;
}
.br-35 {
  border-radius: 35px !important;
}
.br-38 {
  border-radius: 38px !important;
}
.br-40 {
  border-radius: 40px !important;
}
.br-48 {
  border-radius: 48px !important;
}
.br-56 {
  border-radius: 56px !important;
}
.br-120 {
  border-radius: 120px !important;
}
.brp-50 {
  border-radius: 50% !important;
}
.br-box {
  box-sizing: border-box;
}
.border-gray-stroke {
	border: 1px solid $stroke;
}

.border-top-gray-stroke {
  border-top: 1px solid $stroke;
}

.border-left-gray-stroke {
  border-left: 1px solid $stroke;
}

.border-right-gray-stroke {
  border-right: 1px solid $stroke;
}

.border-bottom-gray-stroke {
  border-bottom: 1px solid $stroke;
}

.br-box-shadow {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
}

.divider {
  height: 1px;
  background-color: $stroke;
}
