$customSpacings: 0, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 20, 24, 26, 27, 28, 30, 32, 35, 39, 40, 42, 44, 48, 50, 54, 55, 56, 57, 62, 80, 97, 100, 184, 200, 214, 250, 300, 320, 328, 400, 500;

@each $size in $customSpacings {
	.h-#{$size} {
		height: #{$size}px;
	}

	.min-h-#{$size} {
		min-height: #{$size}px;
	}

	.m-#{$size} {
		margin: #{$size}px !important;
	}

	.mx-#{$size} {
		margin-left: #{$size}px !important;
		margin-right: #{$size}px !important;
	}

	.mx-n#{$size} {
		margin-left: -#{$size}px !important;
		margin-right: -#{$size}px !important;
	}

	.my-#{$size} {
		margin-top: #{$size}px !important;
		margin-bottom: #{$size}px !important;
	}

	.ml-#{$size} {
		margin-left: #{$size}px !important;
	}

	.ml-n#{$size} {
		margin-left: -#{$size}px !important;
	}

	.mr-#{$size} {
		margin-right: #{$size}px !important;
	}

	.mr-n#{$size} {
		margin-right: -#{$size}px !important;
	}

	.mt-#{$size} {
		margin-top: #{$size}px !important;
	}

	.mt-n#{$size} {
		margin-top: -#{$size}px !important;
	}

	.mb-#{$size} {
		margin-bottom: #{$size}px !important;
	}

	.mb-n#{$size} {
		margin-bottom: #{$size}px !important;
	}

	.p-#{$size} {
		padding: #{$size}px !important;
	}

	.px-#{$size} {
		padding-left: #{$size}px !important;
		padding-right: #{$size}px !important;
	}

	.py-#{$size} {
		padding-top: #{$size}px !important;
		padding-bottom: #{$size}px !important;
	}

	.pl-#{$size} {
		padding-left: #{$size}px !important;
	}

	.pr-#{$size} {
		padding-right: #{$size}px !important;
	}

	.pt-#{$size} {
		padding-top: #{$size}px !important;
	}

	.pb-#{$size} {
		padding-bottom: #{$size}px !important;
	}
}

$customWidths: 35, 40, 42, 48, 50, 56, 80, 85, 90, 120, 180, 200, 250, 300, 320;

@each $size in $customWidths {
	.wpx-#{$size} {
		min-width: #{$size}px !important;
		width: #{$size}px !important;
	}

	.hpx-#{$size} {
		min-height: #{$size}px !important;
		height: #{$size}px !important;
	}
}

.h-fit-content {
	height: fit-content !important;
}

.mr-auto {
	margin-right: auto;
}

.w-100 {
	width: 100% !important;
}
