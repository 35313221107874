.ccm-modal {
  .modal-dialog {
    .modal-content {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
      border: 0;
      border-radius: 4px;

      .modal-header {
        display: block;
        text-align: center;
        padding: 32px 40px;

        .header-icon {
          display: block;
          width: 100%;
          margin-bottom: 15px;

          >svg {
            width: 24px;
            height: 24px;
          }
        }

        .header-title {
          >span {
            color: #FF0C2B;
            font-weight: 600;
          }
        }

        .title-header {
          font-size: 16px;
          line-height: 28px;

          .sub-title {
            font-weight: 600;
          }
        }
      }

      .modal-body {
        background: $gray-bg;
        border-radius: 0px 0px 4px 4px;
        padding: 24px 40px;

        .wrap-button {
          margin-top: 24px;
          display: flex;
          flex-wrap: wrap;
          margin: 24px -4px 0;

          .btn {
            flex: 1 1 auto;
            margin: 0 4px;
            padding: 13px 0;
          }
        }
      }
    }
  }

  &__long-term-modal {

    &.operator-modal {
      .modal-header {
        background-color: #F5F6F6;
      }
    }

    .modal-dialog {
      width: 100%;
      max-width: 80vw;


      .modal-content {

        .modal-header {
          display: flex !important;
          border-bottom: 1px solid $stroke !important;
          align-items: center;
          justify-content: space-between;
          padding: 10px 20px;
          margin-bottom: 20px;

          .wrap-button {
            margin: 0;

            .btn {
              width: 106px;
              padding: 8px 0;
            }
          }
        }

        .modal-body {
          background: $white;
          padding: 0;
        }
      }
    }

  }

  &__contractor-modal {

    .modal-dialog {
      .modal-content {
        .modal-body {
          padding: 0;
          border-radius: 4px;
        }
      }
    }

  }
}
