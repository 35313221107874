.dropdown-menu {
    width: max-content !important;
    z-index: 9;
}

.form-control:focus {
    box-shadow: 0 0 0 0.25rem rgba(229, 230, 230, 0.75) !important;
}

.ccm-modal {
    &.ccm-modal__master-data {

        .modal-dialog {

            .modal-header {
                padding: 15px;
                text-align: left;
                font-size: 14px;
                font-weight: 500;
                color: $black-dark-30;

                .header-icon {
                    display: none;
                }
            }

            .modal-body {
                padding: 0;

                .content {
                    background: white;
                    padding: 15px;

                    label {
                        font-size: 13px;
                        color: $gray;
                        font-weight: 400;
                    }
                }

                .wrap-button {
                    margin: 0;
                    padding: 15px;
                    justify-content: flex-end;

                    .btn {
                        width: 120px;
                        flex: inherit;
                        height: 35px;
                        padding: 0;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
