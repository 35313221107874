.admin-app {
  &__main {
    min-height: 100vh;
    padding-top: 0px;
    flex: 1;
    border-right: 1px solid $stroke;
    overflow: hidden;

    &.dashboard {
      overflow: visible;
      margin-top: 64px;
    }
  }
}

.sub-header {
  position: absolute;
  width: 100%;
  background: $white;
  left: 0;
  height: 64px;
  border-bottom: 1px solid $stroke;
  transition: all 0.3s ease;
  z-index: 1;

  transform: translateY(-63px);

  .content-right {
    display: flex;
    align-items: center;
  }

  &.active {
    position: fixed;
    top: 0;
    transform: translateY(0px);
    transition: all 0.3s ease;
  }

  .container {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
  }
}
