.cms-header {
    background: #F5F6F6;
    border-bottom: 2px solid #FF0C2B;
    width: 100%;
    height: 56px;
    position: relative;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        img {
            max-width: 174px;
            cursor: pointer;
        }
    }

    &.active {
        position: fixed;
        top: 0;
        transform: translateY(0px);
        transition: all 0.3s ease;
        z-index: 9;
    }

    &__account {
        display: flex;
        align-items: center;

        &__notification {
            display: flex;
            align-items: center;
            background-color: #EAEAEA;
            padding: 0.5rem;
            border-radius: 4px;
        }

        &__wrapper {
            display: flex;
            align-items: center;
            background-color: #EAEAEA;
            padding: 0.2rem 1.2rem 0.2rem 0.5rem;
            margin-left: 10px;
            border-radius: 4px;

            .wrapper-user {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                overflow: hidden;
            }

            span {
                display: block;
                padding-left: 10px;
                color: $gray;
            }

        }
    }
}

.modal-25w {
    width: 25%;
}

.border-b-modal {
    border-bottom: 1px solid #E1E2EA;
}
