.wrapper-content {
  position: relative;
}

.content-dashboard {
  background-image: url(../../images/bg/bg-dashboard.png);
  background-size: 100% 100%;
  height: calc(100vh - 54px);
  width: 100%;
  position: relative;

  .fullscreen {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
  }

  .dashboard-graph {
    position: absolute;
    bottom: 16px;
    left: 16px;
    width: 70%;
  }

  .bg-coal {
    background-size: 100% 100%;
    height: 100%;
    width: 100%;

    &.bg-coal-getting {
      background-image: url(../../images/bg/bg-dashboard-coal-getting.png);
    }

    &.bg-coal-barging {
      background-image: url(../../images/bg/bg-dashboard-coal-barging.png);
    }

    &.bg-coal-crushing {
      background-image: url(../../images/bg/bg-dashboard-coal-crushing.png);
    }

    &.bg-coal-hauling {
      background-image: url(../../images/bg/bg-dashboard-coal-hauling.png);
    }

    &.bg-coal-loading-barge {
      background-image: url(../../images/bg/bg-dashboard-coal-loading-barge.png);
    }

    &.bg-coal-loading-truck {
      background-image: url(../../images/bg/bg-dashboard-coal-loading-truck.png);
    }

    &.bg-coal-loading-vessel {
      background-image: url(../../images/bg/bg-dashboard-coal-loading-vessel.png);
    }
  }

  .widget {
    display: flex;
    position: absolute;
    bottom: 16px;
    width: 100%;
    padding: 0 16px;
    scale: 0.75;
    transform-origin: bottom left;

    .widget-time,
    .widget-chart {
      background-color: #80a29b;
      padding: 24px 24px 0px;
      color: white;
      border-radius: 8px;
    }

    .widget-time {
      width: 200px;
      margin-right: 24px;
      padding: 16px 16px 0;

      .timebox {
        height: 110px;
        padding: 36px 24px;
        background-color: #76958f;
        width: calc(100% + 30px);
        margin-left: -15px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        white-space: nowrap;
      }
    }

    .widget-chart {
      width: calc(100% - 200px);
      padding: 12px 16px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .widget-legend {
      background-color: #76958f;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      padding: 36px;
      padding-left: 12px;
      padding-right: 80px;
    }
  }
}

.coal-getting {
  --top: 36.03%;
  --left: 15.57%;
  --line: 6.65%;
}

.coal-loading-truck {
  --top: 23.78%;
  --left: 22.7%;
  --line-vertical: 3.63%;
  --line-horizontal: 8.61%;
}

.coal-hauling {
  --top: 10%; //8.93% original
  --left: 34.35%;
  --line-vertical: 5.26%; //6.33% original
  --line-horizontal: 8.38%;
  --line: 8.24%;
  --left-detail-line: 41.6%;
  --top-detail-line: 18%;
}

.coal-crushing {
  --top: 9.24%;
  --left: 52.97%;
  --line-vertical: 6.65%;
  --line-horizontal: 5.56%;
  --line: 8.24%;
  --left-detail-line: 47.45%;
  --top-detail-line: 20%;
}

.coal-loading-barge {
  --top: 30.84%;
  --left: 66.1%;
  --line: 4.67%;
}

.coal-loading-vessel {
  --top: 35.93%;
  --left: 89.54%;
  --line: 7.37%;
}

.coal-barging {
  --top: 53.69%;
  --left: 52%;
  --line: 19.51%;
}

.port-stockpile {
  --top: 15.58%;
  --left: 70.33%;
  --line-vertical: 3.84%;
  --line-horizontal: 15.21%;
}

.rom-stockpile {
  --top: 57.11%;
  --left: 41.02%;
  --line: 18.59%;
}

.dashboard-popup {
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  padding: 0.86em 3.71em 1.14em 0.71em;
  transform: translate(-50%, -100%);
  font-size: 0.73vw;
  white-space: nowrap;
  top: var(--top);
  left: var(--left);

  &.true {
    z-index: 11; //In front of .sub-header
  }

  &.horizontal {
    transform: translate(0);
  }

  &.red {
    border-left: 0.43em solid #ff0c2b;
  }

  &.blue {
    border-left: 0.43em solid #007aff;
  }

  &.orange {
    border-left: 0.43em solid #f2994a;
  }

  &.purple {
    border-left: 0.43em solid #9b51e0;
  }

  &.green {
    border-left: 0.43em solid green;
  }

  .title {
    font-weight: 500;
    font-size: 1em;
    color: #3b3b41;
    margin-bottom: 0.55em;
  }

  .amount {
    font-size: 1em;
    color: #3b3b41;
  }

  .percentage {
    margin-left: 0.57em;
    padding: 0.43em;
    font-weight: 500;
    font-size: 0.86em;
    color: #898993;
    border: 1px solid #e5e6e6;
    border-radius: 8px;
  }

  .pointer {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background: #f5f6f6;
    border-radius: 4px;
    padding: 0.85em;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.line-popup {
  width: 2px;
  background-color: white;
  display: block;
  position: absolute;
  height: var(--line);
  top: var(--top);
  left: var(--left);

  &.horizontal {
    width: var(--line);
    height: 2px;
  }

  &.bottom {
    transform: translateY(-100%);
  }

  &.coal-crushing,
  &.coal-hauling {
    top: var(--top-detail-line);
    left: var(--left-detail-line);

    &.show {
      display: block;
    }

    &.hide {
      display: none;
    }
  }
}

.line-popup-elbow {
  display: block;
  position: absolute;
  width: var(--line-horizontal);
  height: var(--line-vertical);
  top: var(--top);
  left: var(--left);
  background-image: linear-gradient(
    to bottom,
    transparent 50%,
    white 50%,
    white calc(50% + 2px),
    transparent calc(50% + 2px)
  );

  &:before {
    content: '';
    position: absolute;
    left: 0;
    height: 50%;
    top: 0;
    border-left: 2px solid white;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    height: 50%;
    bottom: 0;
    border-right: 2px solid white;
  }

  &.rotate {
    transform: rotateY(180deg) translateX(100%);
  }
}

.stockpile-popup {
  background: #f5f6f6;
  border-radius: 4px;
  font-size: 0.73vw;
  position: absolute;
  transform: translate(-50%, -100%);
  white-space: nowrap;
  top: var(--top);
  left: var(--left);
  z-index: 2;
  padding: 0.86em 4.5em 0.86em 0.86em;

  .title {
    font-weight: 500;
    font-size: 1em;
    color: #3b3b41;
    margin-bottom: 0.55em;
  }

  .amount {
    font-size: 1em;
    color: #3b3b41;
  }
}

.dashboard-detail {
  background-color: #f5f6f6;
  z-index: 3;
  position: absolute;
  left: calc(100% + 8px);
  top: 0;
  border-radius: 8px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  &.red {
    border-left: 0.43em solid #ff0c2b;
  }

  &.blue {
    border-left: 0.43em solid #007aff;
  }

  &.orange {
    border-left: 0.43em solid #f2994a;
  }

  &.purple {
    border-left: 0.43em solid #9b51e0;
  }

  &.green {
    border-left: 0.43em solid green;
  }

  &.coal-loading-barge,
  &.coal-loading-vessel,
  &.coal-barging {
    right: 0;
    left: unset;
    top: calc(100% + 8px);
  }

  .header {
    padding: 0.86em 1.29em;
    font-weight: 500;
    color: #3b3b41;
    background-color: white;
    border-top-right-radius: 8px;
  }

  .content {
    padding: 0.86em;
    display: grid;
    grid-template-columns: repeat(3, auto);
    column-gap: 0.86em;

    .detail-tile {
      background: #ffffff;
      border-radius: 4px;

      .tile-header {
        padding: 0.57em 0.86em;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e5e6e6;

        .tile-title {
          font-weight: 500;
          color: #3b3b41;
        }

        .tile-date {
          font-size: 0.86em;
          color: #6f6f77;
        }
      }

      .tile-body {
        display: grid;
        grid-template-columns: 10em auto;
        row-gap: 0.86em;
        padding: 0.71em 0.86em 1em;

        div:nth-child(2n) {
          text-align: right;
        }

        .tile-percentage {
          font-weight: 500;
          color: #3b3b41;
        }
      }
    }
  }

  // &.coal-crushing {
  //   height: fit-content;
  //   left: -48%;
  //   top: 308%;
  // }

  // &.coal-hauling {
  //   height: fit-content;
  //   left: -30%;
  //   top: 270%;
  // }
}

.apexcharts-grid-borders line:last-child {
  display: none !important;
}

.apexcharts-series > path {
  clip-path: inset(0% 0% 0% 0% round 13px);
}

.apexcharts-series:nth-of-type(2) > path {
  clip-path: inset(0% 0% -13px 0% round 13px);
}
