@font-face {
  font-family: "Slate For OnePlus";
  src: url("./assets/fonts/SlateForOnePlus/SlateForOnePlus.eot");
  /* IE9*/
  src: url("./assets/fonts/SlateForOnePlus/SlateForOnePlus.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("./assets/fonts/SlateForOnePlus/SlateForOnePlus.woff") format("woff"),
    /* chrome、firefox */
    url("./assets/fonts/SlateForOnePlus/SlateForOnePlus.ttf") format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("./assets/fonts/SlateForOnePlus/SlateForOnePlus.svg#Slate For OnePlus") format("svg");
  /* iOS 4.1- */
}

@font-face {
  font-family: "Slate For OnePlus";
  font-weight: 100;
  src: url("./assets/fonts/SlateForOnePlus/SlateForOnePlus-Thin.ttf") format("truetype"),
}

@font-face {
  font-family: "Slate For OnePlus";
  font-weight: 300;
  src: url("./assets/fonts/SlateForOnePlus/SlateForOnePlus-Light.ttf") format("truetype"),
}

@font-face {
  font-family: "Slate For OnePlus";
  font-weight: 500;
  src: url("./assets/fonts/SlateForOnePlus/SlateForOnePlus-Medium.ttf") format("truetype"),
}

@font-face {
  font-family: "Slate For OnePlus";
  font-weight: 700;
  src: url("./assets/fonts/SlateForOnePlus/SlateForOnePlus-Bold.ttf") format("truetype"),
}

@font-face {
  font-family: "Slate For OnePlus";
  font-weight: 900;
  src: url("./assets/fonts/SlateForOnePlus/SlateForOnePlus-Black.ttf") format("truetype"),
}

@import 'assets/styles/global';
