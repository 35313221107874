// colors
.c- {
  &primary {
    color: $primary;
  }

  &gray {
    color: $gray;
  }

  &info {
    color: $blue;
  }

  &warning {
    color: $orange-dark;
  }

  &success {
    color: $green;
  }

  &error {
    color: $red;
  }

  &draft {
    color: $blue;
  }
}

#custom-switch:checked {
  background-color: #35B677 !important;
  border-color: #35B677 !important;
  box-shadow: none !important;
}

#custom-switch:focus, #custom-switch {
  box-shadow: none !important;
  background-color: #E5E6E6;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23ffffff%27/%3e%3c/svg%3e");
}

// background with text
.bg- {
  &primary {
    background: $primary;
    color: $white;
  }

  &gray {
    background: $gray-bg;
    color: $gray;
  }

  &info {
    background: $blue-bg;
    color: $blue;
  }

  &warning {
    background: $orange-bg;
    color: $orange-dark;
  }

  &success {
    background: $green-bg;
    color: $green;
  }

  &error {
    background: $red-bg;
    color: $red;
  }
}
