@mixin bg-variant($parent, $color) {
  #{$parent} {
    background-color: $color !important;
  }
}

.bg-white {
  background-color: $white !important;
}

.bg-transparent {
  background-color: transparent !important;
}

@include bg-variant(".bg-primary", $primary);

@include bg-variant(".bg-secondary", $secondary);

@include bg-variant(".bg-success", $success);

@include bg-variant(".bg-info", $info);

@include bg-variant(".bg-warning", $warning);

@include bg-variant(".bg-danger", $danger);

@include bg-variant(".bg-dark", $gray-dark);

@include bg-variant(".bg-gray", $gray-bg);

@include bg-variant(".bg-gray-dark", $gray-bg-dark);

@include bg-variant(".bg-gray-light", $gray-light);

@include bg-variant(".bg-gray-lighter", $gray-lighter);

@include bg-variant(".bg-blue-light", rgba(0, 122, 255, 0.12));

@include bg-variant(".bg-blue-lighter", #F3F7FC);

@include bg-variant(".bg-blue", #007AFF);

@include bg-variant(".bg-orange", #FF912C);

@include bg-variant(".bg-red", #E91D28);

@include bg-variant(".bg-teal", #35D0DA);

@include bg-variant(".bg-gray-2", #EEEEEE);

.bg-secondary-translucent {
  background-color: rgba($color: $secondary, $alpha: 0.5);
}

.bg-green-light {
  background-color: rgba(21, 165, 95, 0.12) !important;
}

.bg-blue-light {
  background: rgba(0, 122, 255, 0.12) !important;
}

.bg-orange-light {
  background-color: rgba(222, 136, 19, 0.12) !important;
}

.bg-light-blue {
  background-color: rgba(0, 122, 255, 0.12) !important;
}

.bg-purple-light {
  background-color: rgba(104, 72, 195, 0.12) !important;
}

.bg-red-light {
  background-color: rgba(255, 0, 0, 0.12) !important;
}
