@include screen-tablet-above {
  .wrap-login {
    .header-login {
      width: 100%;
      display: flex;
      padding: 10px 25px;
      background-color: #ffffff;

      .logo-left {
        margin-right: 24px;
        width: 157px;
      }

      .logo-middle {
        width: 155px;
      }

      .logo-right {
        margin-left: auto;
        width: 78px;
      }
    }

    .carousel-div {
      // background-image: url(../../images/bg/bg-login.png);
      background-size: cover;
      height: calc(100vh - 44px);
      width: 100vw;
      position: relative;

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.5);
      }
    }

    .login {
      background-color: rgba($color: #ffffff, $alpha: 0.8);
      width: 400px;
      height: 308px;
      border-radius: 5px;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 45%;
      padding: 50px;
      position: absolute;
      text-align: center;

      > img {
        margin-bottom: 40px;
      }

      .btn-auth {
        display: inline-block;
      }
    }
  }
}

@include screen-tablet {
  .wrap-login {
    .header-login {
      width: 100%;
      display: flex;
      padding: 10px 25px;
      background-color: #ffffff;

      .logo-left {
        margin-right: 24px;
        width: 100px;
      }

      .logo-middle {
        width: 100px;
      }

      .logo-right {
        margin-left: auto;
        width: 60px;
      }
    }

    .carousel-div {
      //background-image: url(../../images/bg/bg-login.png);
      background-size: cover;
      height: calc(100vh - 44px);
      width: 100vw;
      position: relative;

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.5);
      }
    }

    .login {
      background-color: rgba($color: #ffffff, $alpha: 0.8);
      width: max-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 5px;
      padding: 20px 50px;
      text-align: center;
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);

      > img {
        margin-bottom: 20px;
      }

      .btn-auth {
        display: inline-block;
      }
    }
  }
}

@include screen-mobile {
  .logo-left {
    margin-right: 24px;
    width: 80px !important;
  }

  .logo-middle {
    width: 80px !important;
  }

  .logo-right {
    margin-left: auto;
    width: 40px !important;
  }
}
