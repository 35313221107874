.btn {
    font-size: 14px;

    &.btn-primary:disabled {
        background-color: #cccccc !important;
        border: 0 !important;
    }

    &.btn-primary-light {
        color: $primary;
        background: rgba(255, 12, 43, 0.08);
    }

    &.btn-primary-outline {
        color: $primary;
        border: 1px solid $primary;
    }

    &.btn-gray-outline {
        border: 1px solid $gray-border;
    }

    &.btn-secondary-outline {
        border: 1px solid $stroke;
    }

    &.btn-light {
        border: 1px solid #cccccc !important;
        background: transparent !important;
    }

    &.btn-red {
        background: #FFE2E5;
        border-radius: 4px;
        border: 0 !important;
    }

    &.btn-green {
        color: #fff;
        background: #36B577;
    }

    &.btn-orange {
        background: rgba(222, 136, 19, 0.12);
        color: rgba(222, 136, 19, 1);
    }

    &.btn-orange:hover {
        background: rgba(222, 136, 19, 0.2);
        color: rgba(222, 136, 19, 0.7);
    }

    &.btn-blue-light {
        background: rgba(0, 122, 255, 0.12);
        color: rgba(0, 122, 255, 1);
    }

    &.btn-blue-light:hover {
        background: rgba(0, 122, 255, 0.2);
        color: rgba(0, 122, 255, 0.7);
    }

}
