.active-tab {
  cursor: pointer;
  width: 100%;
  border-left: 3px solid #FF0C2B;
}

.disable-tab {
  cursor: pointer;
  width: 100%;
  border-left: 3px solid #E5E6E6;
  background-color: #F5F6F6;
  color: #B5B6B6 !important
}

.ccm-waste-removal {

  .content-left {

    .status {
      margin-left: 20px;
      font-size: 14px;
      color: $gray;
      border: 1px solid $stroke;
      border-radius: 4px;
      padding: 6px 8px;
      margin-bottom: 0;
      font-weight: 500;
    }
  }

  &__wrapper {

    .wrapper-search {
      width: 100%;
      height: 56px;
      background: $bright-gray;

      display: flex;
      align-items: center;
      padding: 0 15px;

      .input-group {
        width: 256px;

        span {
          padding-right: 5px;
        }

        input {
          border-left: none;
        }
      }
    }

    .wrapper-action {
      padding: 0 10px;
      width: 100%;
      height: 56px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .dropdown {
        margin-right: 15px;
      }

      button {
        // height: 32px;
      }
    }
  }

  // =========================
  // WASTE REMOVAL DETAIL
  // =========================
  &.detail {

    .sub-header {

      .content-right {

        span {
          color: $gray;
          font-size: 13px;
          font-style: italic;
        }

        button {
          width: 96px;
          margin-left: 10px;
        }
      }
    }

    .content-left {
      display: flex;
      align-items: center;

      button {
        background: transparent;
        display: flex;
        align-items: center;
        border: none;
        outline: none;

        &:focus {
          box-shadow: none;
        }
      }

      h1 {
        margin: 0;
        font-size: 21px;
        padding-left: 15px;
      }
    }

    .wrapper-action {
      padding: 10px;
      border-bottom: 1px solid $stroke;
      display: flex;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;

        svg {
          margin-right: 10px;
        }

        h3 {
          font-size: 16px;
          color: $black-dark-20;
          margin: 0;
        }
      }

      .right {
        display: flex;

        .dropdown {
          margin-right: 15px;
        }
      }
    }

    .wrapper-content {
      background: $gray-bg;
      min-height: 100vh;

      .title {
        padding: 10px;
        font-size: 14px;
        color: $primary;
        font-weight: 500;
      }

      .form-label {
        color: $gray;
        font-size: 11px;
      }

      .wrapper-section {

        background: $white;
        margin-bottom: 8px;
        border-top: 1px solid $stroke;
        border-bottom: 1px solid $stroke;

        .title {}

        .row {
          align-items: center;
        }

        .wrapper-header {

          .col-md-6 {

            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid $stroke;
            min-height: 60px;

            &:first-child {
              border-left: none;
            }

            &:last-child {
              border-right: none;
            }
          }

          .col-md-5 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid $stroke;
            min-height: 60px;
            border-left: none;


          }

          .col-md-7 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid $stroke;
            min-height: 60px;
            border-right: none;
          }

          .left {
            display: flex;
            align-items: center;

            h3 {
              font-size: 14px;
              margin: 0;
              color: $black-dark-20;
              font-weight: 500;
              padding-left: 10px;
            }
          }

          .right {
            display: flex;
            align-items: center;

            h3 {
              margin: 0;
            }

            .ch-header {
              display: flex;
              align-items: center;

              h3 {
                font-size: 20px;
              }

              span {
                display: block;
                font-size: 14px;
                padding-right: 20px;
                padding-left: 20px;
              }
            }
          }
        }

        .total-removal {
          border: 1px solid $stroke;
          border-radius: 4px;
          height: 111px;
          margin: 0 10px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          font-weight: 500;

          .title {
            font-size: 18px;
            padding: 0;
          }

          .total-count {
            font-size: 24px;
          }
        }

        .list-removal {
          background: $bright-gray;
          padding: 10px;
          border-radius: 4px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .item-name {
            font-size: 14px;
            color: $gray;
          }

          .item-count {
            color: $black-dark-20;
            font-size: 14px;
            font-weight: bold;
            margin: 0;
          }
        }
      }

      .wrapper-table {
        // min-height: 140px;
        padding-bottom: 10px;

        .col-md-6 {
          // padding: 0;
        }

        .empety-data {
          text-align: center;
          color: $gray;
          font-size: 14px;
          display: block;
          padding: 30px 0;
        }

        .wrapper-button {
          height: 50px;
          text-align: center;
        }

        .btn {
          margin-top: 10px;

          &.btn-primary {
            width: 51px;
          }

          &.btn-secondary {
            width: 84px;

            &.delete {
              margin-top: 0;
              max-width: 50px;
              padding: 0;
            }
          }
        }

        .table {
          margin-bottom: 0;
        }

        .input-row {
          background: $gray-bg;
          padding: 10px 0;

          .col-md-4 {
            padding: 0 10px;
          }

          .ccm-input-wrapper {

            input[type='text'] {
              // border-left: none;
            }

            input[type="time" i] {
              // display: none;
            }
          }
        }
      }

      .table {

        &.primary {
          td {
            vertical-align: middle;
          }
        }
      }

      .ccm-input-wrapper {

        .no-border {
          border-left: none;
        }

      }

    }

    .action-footer {
      padding: 10px;
      display: flex;

      .upload-document {
        span {
          padding-right: 30px;
          font-size: 14px;
          color: $black-dark-20;
        }

        // min-width: 250px;
      }

      .wrapper-document {
        display: flex;
        flex-wrap: wrap;
        padding: 6px 18px;
        border-radius: 50px;
        border: 1px solid $stroke;
        margin-left: 10px;
        font-size: 14px;
        color: $gray;
        cursor: pointer;
        align-items: center;

        svg {
          margin-left: 10px;
        }
      }
    }
  }

  &.maintenance {
    .table {
      &.primary {
        .dropdown {
          button {
            text-align: left;
          }
        }
      }
    }
  }

  .inp-white {
    background: rgba(255, 255, 255, 0.973) !important;
    // margin-top: 1px;
    padding: 8px !important;
  }

  .inp-white:focus {
    // box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.973) !important;
  }
}
