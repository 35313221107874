.active-tab {
  cursor: pointer;
  width: 100%;
  border-left: 3px solid #ff0c2b;
}

.disable-tab {
  cursor: pointer;
  width: 100%;
  border-left: 3px solid #e5e6e6;
  background-color: #f5f6f6;
  color: #b5b6b6 !important;
}

.ccm-user-management {
  .content-left {
    .status {
      margin-left: 20px;
      font-size: 14px;
      color: $gray;
      border: 1px solid $stroke;
      border-radius: 4px;
      padding: 6px 8px;
      margin-bottom: 0;
      font-weight: 500;
    }
  }

  &__wrapper {
    .wrapper-search {
      width: 100%;
      height: 56px;
      background: $bright-gray;

      display: flex;
      align-items: center;
      padding: 0 15px;

      .input-group {
        width: 256px;

        span {
          padding-right: 5px;
        }

        input {
          border-left: none;
        }
      }
    }

    .wrapper-action {
      padding: 0 10px;
      width: 100%;
      height: 56px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .dropdown {
        margin-right: 15px;
      }

      button {
        // height: 32px;
      }
    }

    .wrapper-checkbox {
      border-top: 1px solid gray;
      border-bottom: 1px solid gray;
    }
  }

  // =========================
  // WASTE REMOVAL DETAIL
  // =========================
  &.detail {
    .sub-header {
      .content-right {
        span {
          color: $gray;
          font-size: 13px;
          font-style: italic;
        }

        button {
          width: 96px;
          margin-left: 10px;
        }
      }
    }

    .content-left {
      display: flex;
      align-items: center;

      button {
        background: transparent;
        display: flex;
        align-items: center;
        border: none;
        outline: none;

        &:focus {
          box-shadow: none;
        }
      }

      h1 {
        margin: 0;
        font-size: 21px;
        padding-left: 15px;
      }
    }

    .wrapper-action {
      padding: 10px;
      border-bottom: 1px solid $stroke;
      display: flex;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;

        svg {
          margin-right: 10px;
        }

        h3 {
          font-size: 16px;
          color: $black-dark-20;
          margin: 0;
        }
      }

      .right {
        display: flex;

        .dropdown {
          margin-right: 15px;
        }
      }
    }

    .wrapper-content {
      background: $gray-bg;
      min-height: 100vh;

      .title {
        padding: 10px;
        font-size: 14px;
        color: $primary;
        font-weight: 500;
      }

      .title-2 {
        padding: 20px 10px;
        font-size: 16px;
        color: #3e3e49;
        font-weight: 500;
        margin: 0;
      }

      .form-label {
        color: $gray;
        font-size: 11px;
      }

      .wrapper-section {
        background: $white;
        margin-bottom: 8px;
        border-top: 1px solid $stroke;
        border-bottom: 1px solid $stroke;

        .title {
        }

        .row {
          align-items: center;
        }

        .wrapper-header {
          .col-md-12 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid $stroke;
            min-height: 60px;

            &:first-child {
              border-left: none;
            }

            &:last-child {
              border-right: none;
            }

            .form-check-label {
              padding-left: 0.25rem;
              font-size: 14px;
              font-weight: 400;
            }

            .form-check-input {
              width: 18px;
              height: 18px;
            }

            .form-check-input:checked[type="checkbox"] {
              background-color: #189e2e;
              border-color: #189e2e;
              background-image: url('data: image/svg+xml,<svg width="2" height="1" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.91629 0.284424C10.1946 0.580324 10.1946 1.06007 9.91629 1.35597L3.93525 7.71558C3.80162 7.85767 3.62037 7.9375 3.43137 7.9375C3.24238 7.9375 3.06113 7.85767 2.92749 7.71558L0.208714 4.82471C-0.0695712 4.52881 -0.0695712 4.04906 0.208714 3.75316C0.486999 3.45726 0.938188 3.45726 1.21647 3.75316L3.43137 6.10826L8.90853 0.284424C9.18681 -0.0114748 9.638 -0.0114748 9.91629 0.284424Z" fill="white" /></svg>');
            }

            .form-check-input:focus {
              border-color: gray;
              outline: 0;
              box-shadow: 0 0 0 0 rgba(255, 12, 43, 0.25);
            }
          }

          .col-md-6 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid $stroke;
            min-height: 60px;

            &:first-child {
              border-left: none;
            }

            &:last-child {
              border-right: none;
            }
          }

          .col-md-5 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid $stroke;
            min-height: 60px;
            border-left: none;
          }

          .col-md-7 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid $stroke;
            min-height: 60px;
            border-right: none;
          }

          .left {
            display: flex;
            align-items: center;

            h3 {
              font-size: 14px;
              margin: 0;
              color: $black-dark-20;
              font-weight: 500;
              padding-left: 10px;
            }
          }

          .right {
            display: flex;
            align-items: center;

            h3 {
              margin: 0;
            }

            .ch-header {
              display: flex;
              align-items: center;

              h3 {
                font-size: 20px;
              }

              span {
                display: block;
                font-size: 14px;
                padding-right: 20px;
                padding-left: 20px;
              }
            }
          }
        }

        .total-removal {
          border: 1px solid $stroke;
          border-radius: 4px;
          height: 111px;
          margin: 0 10px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          font-weight: 500;

          .title {
            font-size: 18px;
            padding: 0;
          }

          .total-count {
            font-size: 24px;
          }
        }

        .list-removal {
          background: $bright-gray;
          padding: 10px;
          border-radius: 4px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .item-name {
            font-size: 14px;
            color: $gray;
          }

          .item-count {
            color: $black-dark-20;
            font-size: 14px;
            font-weight: bold;
            margin: 0;
          }
        }

        .accordion {
          .accordion-item {
            border: 0;

            .accordion-header {
              .accordion-button {
                border-bottom: 1px solid #e5e6e6;
                padding: 25px 10px;

                &:focus {
                  border-color: none;
                  outline: 0;
                  box-shadow: none;
                }

                &:not(.collapsed) {
                  background: $white;
                  color: #3e3e49;
                }
              }
            }

            .accordion-collapse {
              border-left: none;

              .accordion-body {
                padding: 0;
              }
            }
          }
        }

        .table-responsive {
          .table.primary.role {
            th {
              color: #3b3b41;
              font-weight: 500;
              text-align: center;
              padding-right: 0;
              background-color: white;
            }

            tr:first-child {
              background-color: #f5f6f6;
            }

            .role.form-check {
              display: flex;
              justify-content: center;

              .form-check-input {
                border: 1px solid rgba(51, 51, 51, 0.25);
                width: 18px;
                height: 18px;
              }

              .form-check-input:checked[type="checkbox"] {
                background-color: #189e2e;
                border-color: #189e2e;
                background-image: url('data: image/svg+xml,<svg width="2" height="1" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.91629 0.284424C10.1946 0.580324 10.1946 1.06007 9.91629 1.35597L3.93525 7.71558C3.80162 7.85767 3.62037 7.9375 3.43137 7.9375C3.24238 7.9375 3.06113 7.85767 2.92749 7.71558L0.208714 4.82471C-0.0695712 4.52881 -0.0695712 4.04906 0.208714 3.75316C0.486999 3.45726 0.938188 3.45726 1.21647 3.75316L3.43137 6.10826L8.90853 0.284424C9.18681 -0.0114748 9.638 -0.0114748 9.91629 0.284424Z" fill="white" /></svg>');
              }

              .form-check-input:focus {
                border-color: gray;
                outline: 0;
                box-shadow: 0 0 0 0 rgba(255, 12, 43, 0.25);
              }
            }

            .role-select-all.form-check {
              .form-check-label {
                padding: 3px 0 0 5px;
              }

              .form-check-input {
                border: 1px solid rgba(51, 51, 51, 0.25);
                width: 18px;
                height: 18px;
              }

              .form-check-input:checked[type="checkbox"] {
                background-color: #189e2e;
                border-color: #189e2e;
                background-image: url('data: image/svg+xml,<svg width="2" height="1" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.91629 0.284424C10.1946 0.580324 10.1946 1.06007 9.91629 1.35597L3.93525 7.71558C3.80162 7.85767 3.62037 7.9375 3.43137 7.9375C3.24238 7.9375 3.06113 7.85767 2.92749 7.71558L0.208714 4.82471C-0.0695712 4.52881 -0.0695712 4.04906 0.208714 3.75316C0.486999 3.45726 0.938188 3.45726 1.21647 3.75316L3.43137 6.10826L8.90853 0.284424C9.18681 -0.0114748 9.638 -0.0114748 9.91629 0.284424Z" fill="white" /></svg>');
              }

              .form-check-input:focus {
                border-color: gray;
                outline: 0;
                box-shadow: 0 0 0 0 rgba(255, 12, 43, 0.25);
              }
            }
          }

          .table.primary th {
            color: #3b3b41;
            font-weight: 500;
            text-align: center;
            padding-right: 0;
          }

          .table.primary th:nth-child(2) {
            border-left: 1px solid #e5e6e6;
            border-right: 1px solid #e5e6e6;
          }

          .table.primary th:nth-child(4) {
            border-left: 1px solid #e5e6e6;
            border-right: 1px solid #e5e6e6;
          }
        }

        .legend-container {
          width: fit-content;
          margin: 0.5rem;
          padding: 0.5rem 0;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          border: 1px solid #e5e6e6;
          border-radius: 8px;
        }

        .legend-content {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 0.5rem;
        }

        .legend-bar-actual {
          height: 14px;
          width: 8px;
          background-color: #3b3b41;
          border-radius: 4px;
          margin-right: 0.3rem;
        }

        .legend-bar-plan {
          height: 14px;
          width: 8px;
          background-color: #c4c4c6;
          border-radius: 4px;
          margin-right: 0.3rem;
        }

        .legend-line-plan {
          height: 4px;
          width: 14px;
          background-color: #ff0c2b;
          border-radius: 4px;
          margin-right: 0.3rem;
        }

        .legend-line-actual {
          height: 4px;
          width: 14px;
          background-color: #007aff;
          border-radius: 4px;
          margin-right: 0.3rem;
        }

        .legend-line-plan-shift {
          border: 2px dashed #df8109;
          width: 15px;
          border-radius: 4px;
          margin-right: 0.3rem;
        }

        .canvas-container {
          width: 95%;
          height: 500px;
          display: flex;
          align-items: center;
        }

      }

      .wrapper-table {
        // min-height: 140px;
        padding-bottom: 10px;

        .col-md-6 {
          // padding: 0;
        }

        .empety-data {
          text-align: center;
          color: $gray;
          font-size: 14px;
          display: block;
          padding: 30px 0;
        }

        .wrapper-button {
          height: 50px;
          text-align: center;
        }

        .btn {
          margin-top: 10px;

          &.btn-primary {
            width: 51px;
          }

          &.btn-secondary {
            width: 84px;

            &.delete {
              margin-top: 0;
              max-width: 50px;
              padding: 0;
            }
          }

          &.btn-table {
            background-color: #f5f6f6;
            border-color: #f5f6f6;
          }
        }

        .table {
          margin-bottom: 0;
        }

        .input-row {
          background: $gray-bg;
          padding: 10px 0;

          .col-md-4 {
            padding: 0 10px;
          }

          .ccm-input-wrapper {
            input[type="text"] {
              // border-left: none;
            }

            input[type="time" i] {
              // display: none;
            }
          }
        }

        .content-right {
          padding-right: 1rem;
          display: flex;
          align-items: center;

          button {
            width: 96px !important;
            margin-left: 10px;
          }
        }

        .isEdit {
          width: 60% !important;
        }
      }

      .table {
        &.primary {
          td {
            vertical-align: middle;
            border: 1px solid #e5e6e6;
          }
        }
      }

      .ccm-input-wrapper {
        .no-border {
          border-left: none;
        }
      }
    }

    .action-footer {
      padding: 10px;
      display: flex;

      .upload-document {
        span {
          padding-right: 30px;
          font-size: 14px;
          color: $black-dark-20;
        }

        // min-width: 250px;
      }

      .wrapper-document {
        display: flex;
        flex-wrap: wrap;
        padding: 6px 18px;
        border-radius: 50px;
        border: 1px solid $stroke;
        margin-left: 10px;
        font-size: 14px;
        color: $gray;
        cursor: pointer;
        align-items: center;

        svg {
          margin-left: 10px;
        }
      }
    }
  }

  &.maintenance {
    .table {
      &.primary {
        .dropdown {
          button {
            text-align: left;
          }
        }
      }
    }
  }
}
