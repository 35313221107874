.ccm-grid-list {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  font-size: 14px;
  white-space: nowrap;
  width: 100%;
  min-width: min-content;

  .grid-group-item {
    display: grid;
    grid-auto-flow: column;
  }

  .list-item {
    height: 48px;
    padding: 16px;
    border-bottom: solid var(--border-color) 1px
  }
}
