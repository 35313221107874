.admin-app {
  &__sidebar {
    border-right: 1px solid $stroke;
    width: 240px;
    min-height: 100vh;
    padding: 15px;
    padding-top: 0px;
    padding-left: 0;

    .logo-wrapper {
      border-radius: 4px;
      border: 1px solid $stroke;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;


      img {
        max-width: 111px;
      }
    }

    .menu-item {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: $gray;
      text-decoration: none;
      cursor: pointer;

      padding: 0 15px;
      margin-top: 10px;
      height: 36px;

      &.active {
        background-color: $gray-bg;
        border-radius: 4px;
        color: $primary;
        font-weight: 500;
      }

      svg {
        margin-right: 10px;
      }
    }

    .accordion {

      .accordion-item {
        border: 0;
        // padding: 0 13px;
        margin-top: 10px;
      }

      .accordion-button {
        padding: 8px 15px;
        font-size: 14px;
        color: $gray;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;

        &:focus {
          border-color: transparent;
          box-shadow: none;
        }

        &::after {
          background-image: url("data:image/svg+xml,%3Csvg width='4' height='5' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.477629 7.94643L5.43858 4.34786C5.48758 4.31299 5.52834 4.26242 5.5567 4.20131C5.58506 4.14019 5.6 4.07073 5.6 4C5.6 3.92927 5.58506 3.85981 5.5567 3.7987C5.52834 3.73758 5.48758 3.68701 5.43858 3.65214L0.47763 0.0535683C0.429224 0.0184749 0.374315 6.13592e-09 0.318421 0C0.262527 -6.13592e-09 0.207618 0.0184749 0.159212 0.0535683C0.11062 0.088796 0.0703038 0.139521 0.0423457 0.200608C0.0143876 0.261695 -0.000219739 0.330975 3.01121e-06 0.401431L2.51015e-06 7.59857C-0.000220249 7.66903 0.0143871 7.73831 0.0423452 7.79939C0.0703033 7.86048 0.110619 7.9112 0.159211 7.94643C0.207617 7.98152 0.262526 8 0.31842 8C0.374314 8 0.429223 7.98152 0.477629 7.94643Z' fill='%23000' /%3E%3C/svg%3E");
          background-position: center;
          background-size: 6px;
          margin-left: 0;
          margin-right: 7px;
        }


        svg {
          margin-right: 10px;
        }
      }

      .accordion-button:not(.collapsed) {
        box-shadow: none;
        border-radius: 4px;
        background: transparent;

        &::after {
          transform: rotate(90deg);
        }

        svg {

          path {
            fill: $white;
          }
        }
      }

      .accordion-body {
        padding: 0;

        .menu-item {
          padding-left: 40px;
          text-decoration: none;

          &.active {
            background-color: $gray-bg;
            border-radius: 4px;
            color: $primary;
            font-weight: 500;
          }
        }
      }
    }

    .menu-detail {
      position: relative;

      .menu-item {
        font-size: 14px;
        font-weight: 400;
        color: $black-dark-20;
        border-radius: 4px;
        padding-left: 38px;
        opacity: .6;
        text-decoration: none;
        // cursor: pointer;

        &::after {
          content: "";
          position: absolute;
          left: 15px;
          width: 8px;
          height: 8px;
          background: $stroke;
          border-radius: 2px;
        }

        &.active {
          background: $gray-bg;
          opacity: 1;

          &::after {
            background: $orange-darken;
          }
        }
      }
    }

  }

  .ccm-sidebar {
    &__configuration {
      .accordion-button {
        flex-direction: row !important;
        justify-content: space-between !important;

        &::after {
          background-image: url("data:image/svg+xml,%0A%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 4H9C9.26522 4 9.51957 4.10536 9.70711 4.29289C9.89464 4.48043 10 4.73478 10 5C10 5.26522 9.89464 5.51957 9.70711 5.70711C9.51957 5.89464 9.26522 6 9 6H6V9C6 9.26522 5.89464 9.51957 5.70711 9.70711C5.51957 9.89464 5.26522 10 5 10C4.73478 10 4.48043 9.89464 4.29289 9.70711C4.10536 9.51957 4 9.26522 4 9V6H1C0.734784 6 0.48043 5.89464 0.292893 5.70711C0.105357 5.51957 0 5.26522 0 5C0 4.73478 0.105357 4.48043 0.292893 4.29289C0.48043 4.10536 0.734784 4 1 4H4V1C4 0.734784 4.10536 0.48043 4.29289 0.292893C4.48043 0.105357 4.73478 0 5 0C5.26522 0 5.51957 0.105357 5.70711 0.292893C5.89464 0.48043 6 0.734784 6 1V4Z' fill='%23384047'/%3E%3C/svg%3E%0A");
          background-size: 10px;
        }
      }

      .accordion-button:not(.collapsed) {
        &::after {
          transform: none;
          background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
        }
      }
    }
  }
}
