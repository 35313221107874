
.ccm-breadcrumbs {
    background-color: var(--white) !important;
    border: 0;
    padding-left: 15px;
    
    ol {
        margin-bottom: 0;
    }
  
    .breadcrumb-item {
  
        a {
            color: $black-dark;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-decoration: none;
  
            &:hover {
                color: var(--primary);
            }
        }
    }
  
    .breadcrumb-item+.breadcrumb-item::before {
        content: ">";
    }
  }