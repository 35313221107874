.dropdown {
  min-width: 104px;

  &-toggle {
    width: 100%;

    &.btn-success {
      background: $gray-bg;
      outline: none;
      border: none;

      &:focus {
        box-shadow: 0 0 0 0.25rem rgba(74, 71, 236, .25);
      }
    }

    background: $gray-bg !important;
    border: none !important;

    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(74, 71, 236, .25) !important;
    }

    &::after {
      position: absolute;
      width: 35px;
      height: 23px;
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.98771 6.50597C5.25288 6.50597 5.50718 6.40066 5.69471 6.21319L9.69471 2.21319C9.79022 2.12094 9.8664 2.01059 9.91881 1.88859C9.97122 1.76659 9.99881 1.63537 9.99996 1.50259C10.0011 1.36981 9.97581 1.23813 9.92553 1.11523C9.87525 0.992336 9.801 0.880683 9.70711 0.78679C9.61321 0.692898 9.50156 0.618644 9.37867 0.568363C9.25577 0.518082 9.12409 0.49278 8.99131 0.493934C8.85853 0.495088 8.72731 0.522675 8.60531 0.575084C8.4833 0.627493 8.37296 0.703674 8.28071 0.799184L4.98771 4.09219L1.69471 0.799184C1.50611 0.617026 1.25351 0.516232 0.991312 0.51851C0.729115 0.520788 0.478303 0.625958 0.292894 0.811366C0.107485 0.996774 0.00231614 1.24759 3.78002e-05 1.50978C-0.00224054 1.77198 0.0985533 2.02458 0.280712 2.21319L4.28071 6.21319C4.46824 6.40066 4.72255 6.50597 4.98771 6.50597Z' fill='%236B6B7D'/%3E%3C/svg%3E%0A");
      right: 0;
      border: none;
      background-repeat: no-repeat;
      background-position: center;
      top: 6px;
    }
  }

  button {
    text-align: left;
    position: relative;
    font-size: 16px;
    line-height: 1.5;
    color: $gray;
    padding-right: 30px;
    min-height: 36px;
  }

  &-menu {
    width: 100%;
  }
}

.dropdown-white {

  &.dropdown {

    button {
      &:disabled {
        background: #e9ecef !important;
        // match input component
        opacity: 1;
        border: 1px solid #ced4da !important;
      }

      background-color: $white !important;
      border: 1px solid $stroke !important;

      &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22' fill='none'%3E%3Crect width='22' height='22' rx='4' fill='%23E1E2EA'/%3E%3Cpath d='M10.9877 14C11.2529 14 11.5072 13.8949 11.6947 13.7078L15.6947 9.71584C15.7902 9.62377 15.8664 9.51365 15.9188 9.39189C15.9712 9.27013 15.9988 9.13918 16 9.00666C16.0011 8.87415 15.9758 8.74274 15.9255 8.62009C15.8753 8.49744 15.801 8.38601 15.7071 8.29231C15.6132 8.1986 15.5016 8.1245 15.3787 8.07432C15.2558 8.02414 15.1241 7.99889 14.9913 8.00004C14.8585 8.00119 14.7273 8.02872 14.6053 8.08102C14.4833 8.13333 14.373 8.20936 14.2807 8.30467L10.9877 11.5911L7.69471 8.30467C7.50611 8.12288 7.25351 8.02229 6.99131 8.02456C6.72912 8.02684 6.4783 8.1318 6.29289 8.31683C6.10749 8.50187 6.00232 8.75218 6.00004 9.01385C5.99776 9.27552 6.09855 9.52761 6.28071 9.71584L10.2807 13.7078C10.4682 13.8949 10.7225 14 10.9877 14Z' fill='%2387879F'/%3E%3C/svg%3E");
      }
    }
  }

}

.params-dropdown {
  &.dropdown {
    button {
      &:disabled {
        background: #e9ecef !important;
        // match input component
        opacity: 1;
        border: 1px solid #ced4da !important;
      }

      background-color: $white !important;
      border: 1px solid $stroke !important;
    }
  }
}
