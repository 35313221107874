/*
    this sheet is for primary App styles
*/

body {
  margin: 0;
  font-family: 'Slate For OnePlus';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.accordion {
  // width: max-content;
}

.accordion-sub {
  margin-left: 1rem;
}

.accordion-collapse {
  border-left: gray 1px solid;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tag-label {
  border-radius: $border-radius;
  padding: 4px 8px;
  margin: auto 4px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.select-separator {
  align-self: stretch;
  width: 1px;
  background-color: rgb(204, 204, 204);
  margin-bottom: 8px;
  margin-top: 8px;
  box-sizing: border-box;
}

.fixed-log {
  display: block;
  position: absolute;
  right: 0px;
  z-index: 6;
  background: rgb(255, 255, 255)
}

@for $i from 0 through 200 {
  #react-select-#{$i}-listbox {
    z-index: 10 !important;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.border-t {
  border-top: 1px solid lightgray;
}

.border-b {
  border-bottom: 1px solid lightgray
}

.border-l {
  border-left: 1px solid lightgray
}

@include screen-desktop {
  .container {
    max-width: 95vw;
  }
}

.active-tab {
  cursor: pointer;
  width: 100%;
  border-left: 3px solid #FF0C2B;
}

.disable-tab {
  cursor: pointer;
  width: 100%;
  border-left: 3px solid #E5E6E6;
  background-color: #F5F6F6;
  color: #B5B6B6 !important
}

.display-none {
  display: none;
}

.collapse.show {
  visibility: visible !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.wrapper-action {
  position: relative;
  z-index: 2;
}
